window.toggle_auth_app_qr_codes = function toggle_auth_app_qr_codes(selected_store, deselected_store){
  var qr_codes = $('#auth-app-qr-codes');
  var selected_store_codes = $('#' + selected_store + '-qr-codes');
  var deselected_store_codes = $('#' + deselected_store + '-qr-codes');

  if (qr_codes.hasClass('hide')) {
    deselected_store_codes.addClass('hide');
    selected_store_codes.removeClass('hide');
    qr_codes.removeClass('hide');
  } else if (selected_store_codes.hasClass('hide')) {
    deselected_store_codes.addClass('hide');
    selected_store_codes.removeClass('hide');
  } else {
    qr_codes.addClass('hide');
    deselected_store_codes.addClass('hide');
    selected_store_codes.addClass('hide');
  }
}
