///////////////////////////////////////////////////////////////////////////////
// nach spalten filtern auf schulverwaltungs#index und #index_anstellungen
//
// gets called after click on filter button.
// marks filter button with lime background (signal: filter on) and changes
// button icon and tooltip to "filter off"
//
// there are two ways to filter: a) click on an activated col header ->
// filter by preconfigured function, usually for cells which are not empty,
// otherwise by specified functions ("filter_special"). b) by clicking
// on one of the cell values in a col where these are activated -> filter
// by this value.
//
// filtering on severals cols is AND (all conditions must be satisfied).
//
// headers of filtered cols are marked, and another click on them removes
// filter on this col.
//
// another click on filter button turns all filters off.
///////////////////////////////////////////////////////////////////////////////

window.sv_filter_table_by_cols = function sv_filter_table_by_cols(target, table){

  // toggle filter appearance for "filter on" or "filter off"
  target.closest('li').classList.toggle('lime');
  target.closest('li').classList.toggle('lighten-2');

  var button = $('#sv-index-filter-button');
  button.toggleClass('sv-index-filter-button-active');

  var filterable_elts = $(table).find('.sv-filterable');

  // turn filter on
  if (button.hasClass('sv-index-filter-button-active')){
    button.find('i').text('zoom_out');
    button.attr('data-tooltip', 'Filter aus');
    M.Tooltip.init(button);

    window.select_by_values = {};
    window.filter_special = {};

    // get cells which trigger filtering when clicked on
    // these are most col headers, and cell values of some cols.
    // listener handles all filtering, on and off.

    $(filterable_elts)
      .addClass('sv-filter-active no-propagation')
      .css('cursor', 'pointer')
      .on('click.sv_colors', function(e){ filter(e, table); });

  // turn filter off
  } else {

    // restore filter button appearance
    button.find('i').text('search');
    button.attr('data-tooltip', 'Filter');
    M.Tooltip.init(button.tooltip);

    // inactivate filterable cells
    $(filterable_elts)
      .removeClass('sv-filter-active sv-filter-selected no-propagation')
      .css('cursor', 'auto')
      .off('click.sv_colors');

    // show all rows except anstellungen rows in sv#index
    $(table).find('tbody tr:not(.no-filter)').removeClass('hide');
  }
}

window.filter = function filter(e, table){
  var click_elt;
  var click_elt_closest_td;
  var click_elt_closest_th;
  var value;
  var filter_active_td_indexes;
  var row;
  var function_to_apply;
  var col_nr;
  var col_name;
  var hide_this_row;

  click_elt = e.target;
  click_elt_closest_td = $(click_elt).closest('td');

  // if clicked on cell to filter by
  if ($(click_elt).closest('tr')[0].rowIndex > 0 &&
      click_elt_closest_td.hasClass('sv-filter-from-cell-value')){

    // get col index
    col_nr = click_elt_closest_td[0].cellIndex;

    // get value to filter by
    window.select_by_values[col_nr] = $(click_elt).text().trim();

    // activate col header -> mark col as filtered and
    // can turn filter off by clicking on header
    $(table).find('thead th').eq(col_nr).addClass('sv-filter-selected');

  // if clicked on col header
  } else {

    // mark col as filtered
    click_elt_closest_th = $(click_elt).closest('th');
    click_elt_closest_th.toggleClass('sv-filter-selected');

    // if filter by specified function, specify in filter_special
    if (click_elt_closest_th.hasClass('sv-filterable-special')){
      col_nr = $(click_elt_closest_th)[0].cellIndex;
      col_name = click_elt_closest_th.attr('data-col-name');

      if (click_elt_closest_th.hasClass('sv-filter-sem1-sem2-greater-zero')){
        window.filter_special[col_nr] = two_sem_greater_zero;

      } else if (col_name == 'neuanstellung'){
        window.filter_special[col_nr] =
          function(value) { return filter_equals_value(value, "ja"); };

      } else if (col_name == 'berufseinfuehrung'){
        window.filter_special[col_nr] =
          function(value) { return filter_equals_value(value, "ja"); };

      } else if (col_name == 'quest'){
        window.filter_special[col_nr] =
          function(value) { return filter_equals_value(value, "ja"); };

      } else if (col_name == 'anstellungen'){
        window.filter_special[col_nr] =
          function(value){ return filter_greater_than(value, 0); };

      } else if (col_name == 'std_altersentlastung'){
        window.filter_special[col_nr] =
          function(value){ return filter_greater_than(value, 0); };

      }  else if (col_name == 'wl'){
        window.filter_special[col_nr] = two_sem_greater_zero;

      } else if (col_name == 'bg'){
        window.filter_special[col_nr] = two_sem_greater_zero;

      } else if (col_name == 'alter_stichtag'){
        window.filter_special[col_nr] =
          function(value) { return filter_greater_than(value, 60); };

      } else if (col_name == 'bg_abweichung_richtwert'){
        window.filter_special[col_nr] = two_sem_not_zero;
      }
    }
  }

  // col indexes of all currently filtered cols
  filter_active_td_indexes = $(table)
    .find('thead th.sv-filter-selected')
    .map(function(){
      return $(this)[0].cellIndex;
    });

  // if selection is not active on any col, show all rows.
  if (filter_active_td_indexes.length == 0){
    $(table).find('tbody tr:not(.no-filter)').removeClass('hide');

  // show rows according to filter
  } else {
    $(table).find('tbody tr').each(function() {
      row = $(this);

      if ($(row).hasClass('no-filter')){
        row.addClass('hide');
        return true;
      }

      // for each filtered col, check cell value
      filter_active_td_indexes.each(function(){
        col_nr = $(this)[0];

        value = row.find('td').eq(col_nr - 1).text().trim();

        // specific value has to be matched
        if (col_nr in window.select_by_values){

          hide_this_row = value != window.select_by_values[col_nr];

        // filter by specified function
        } else if (col_nr in window.filter_special){
            function_to_apply = window.filter_special[col_nr];
            hide_this_row = !function_to_apply(value);

        // default: filter if cell is not blank
        } else {
          hide_this_row = value == '';
        }

        if (hide_this_row){ row.addClass('hide'); return false; };
        row.removeClass('hide');
      });
    });
  }
}

window.two_sem_greater_zero = function two_sem_greater_zero(value){
  if (value.includes('/')) { return true; }
  value = parseFloat(value);
  if (isNaN(value)) { return false; }
  return (value > 0 ? true : false);
}

window.two_sem_not_zero = function two_sem_not_zero(value){
  if (value.includes('/')) { return true; }
  value = parseFloat(value);
  if (isNaN(value)) { return false; }
  return (value != 0 ? true : false);
}

window.filter_greater_than = function filter_greater_than(value, greater_than_value){
  value = parseFloat(value);
  greater_than_value = parseFloat(greater_than_value);
  if (isNaN(value) || isNaN(greater_than_value)) { return false; }
  return (value > greater_than_value ? true : false);
}

window.filter_equals_value = function filter_equals_value(value, equals_value){
  return (value == equals_value ? true : false);
}

///////////////////////////////////////////////////////////////////////////////
// Zellen markieren auf schulverwaltungs#index
///////////////////////////////////////////////////////////////////////////////

// color code one cell in sv#index or sv#index_anstellungen and save to db
window.set_sv_color = function set_sv_color(e, table, action_name) {
  var elt = e.target.closest("td, th"); // closest td or th to html elt clicked on
  if ($(elt).hasClass('sv-filter-active')){
    return;
  }

  var elt_col_name = table.rows[0].cells[elt.cellIndex].getAttribute('data-col-name');
  var elt_closest_tr = $(elt).closest("tr");
  var undeletable_person_id = elt_closest_tr && elt_closest_tr.attr('data-undeletable-person-id');
  var a_key = action_name == "index_anstellungen" ?
    (elt_closest_tr && elt_closest_tr.attr('data-a-key')) : null;

  var path = "/schulverwaltungs/" + undeletable_person_id + "/save_sv_cell_color";

  $.ajax({
    type: "PATCH",
    url: path,
    dataType: "script",
    data: {
      a_key: a_key,
      id: undeletable_person_id,
      elt_col_name: elt_col_name,
      action_name: action_name,
      authenticity_token: $('[name="csrf-token"]')[0].content
    },
    complete: function(xmlHTTP, status){
      if (status == "success"){
        var data = $.parseJSON(xmlHTTP.responseText)[0];
        $(elt).removeClass(data.old_color).addClass(data.new_color);
      } else if (status == "nocontent"){
        // do nothing
      } else {
        M.toast({html: "Die Farbe konnte nicht gesetzt werden. Bitte versuchen Sie es nochmal, " +
        "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 71)"});
      }
    }
  });

  return false; // don't follow html link, i.e. don't reload page
}

// reset all cell colors on sv#index or sv#index_anstellungen
window.sv_markierung_loeschen = function sv_markierung_loeschen(colored_cells, action_name){

  // get rows with color coded cells
  var ids = $(colored_cells)
    .closest('tr')
    .map(function() {
      return $(this).attr('data-undeletable-person-id');
    })
    .get()

  // eliminate duplicate values
  ids = [...new Set(ids)];

  // if color coded cells are present
  if (ids.length > 0){

    var path = "/schulverwaltungs/reset_all_sv_cell_colors"
    $.ajax({
      type: "PATCH",
      url: path,
      dataType: "script",
      data: { cell_update_ids: ids,
              action_name: action_name,
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
      complete: function(xmlHTTP, status){
        if (status == "success"){
          var data = $.parseJSON(xmlHTTP.responseText)[0];
          $(colored_cells).removeClass(data.classes);
        } else {
          M.toast({html: "Die Farben konnten nicht gelöscht, " +
          "werden. Bitte laden Sie die Seite neu und versuchen Sie es nochmal, " +
          "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 73)"});
        }
      }
    });
  }
}

// reset all cell colors on sv#interactive_index
window.interactive_sv_markierung_loeschen =
    function interactive_sv_markierung_loeschen(colored_cells){

  // get undeletable_person_ids
  var id;
  var upids = $(colored_cells)
    .closest('li.svp-header-li')
    .map(function() {
      id = $(this).attr('id') ? $(this).attr('id').slice(9) : null;
      return id;
    })
    .get()

  // eliminate duplicate values
  upids = [...new Set(upids)];

  // if color coded cells are present
  if (upids.length > 0){
    var path = "/schulverwaltungs/reset_all_sv_cell_colors"

    $.ajax({
      type: "PATCH",
      url: path,
      dataType: "script",
      data: { cell_update_ids: upids,
              action_name: "interactive_index",
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
      complete: function(xmlHTTP, status){
        if (status == "success"){
          var data = $.parseJSON(xmlHTTP.responseText)[0];
          $(colored_cells).removeClass(data.classes);
        } else {
          M.toast({html: "Die Farben konnten nicht gelöscht, " +
          "werden. Bitte laden Sie die Seite neu und versuchen Sie es nochmal, " +
          "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 73a)"});
        }
      }
    });
  }
}

// used on sv#interactive_index and sv#index, not available on index_anstellungen.
window.sv_remove_all_freigabe_notifications =
    function sv_remove_all_freigabe_notifications(alert_elts){

  var p1 = alert_elts.closest('.svp-header-li');
  var p2 = alert_elts.closest('tr');
  var p_elts = p1.add(p2);

  var upids = p_elts
    .map(function() {
      return $(this).attr('id') ? $(this).attr('id').slice(9) : null;
      })
    .get()

  // eliminate duplicate values
  upids = [...new Set(upids)];

  if (upids.length > 0){
    var path = "/schulverwaltungs/remove_all_freigabe_notifications"

    $.ajax({
      type: "PATCH",
      url: path,
      dataType: "script",
      data: { upids: upids,
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
      complete: function(xmlHTTP, status){
        if (status == "success"){
          $(alert_elts).remove();
        } else {
          M.toast({html: "Die Freigabe-Anzeigen konnten nicht entfernt, " +
          "werden. Bitte laden Sie die Seite neu und versuchen Sie es nochmal, " +
          "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 73d)"});
        }
      }
    });
  }
}

///////////////////////////////////////////////////////////////////////////////
// anstellungen einer person in tabelle ein- und ausblenden
///////////////////////////////////////////////////////////////////////////////

window.toggle_person_anstellungen_in_table = function toggle_person_anstellungen_in_table(target_element){
  var row_index = target_element.rowIndex;
  var nr = target_element.innerHTML;

  if (nr == 0){
    M.toast({html: "Diese Person hat keine Anstellungen."});
  } else {
    const elts = $(target_element).closest('tr').nextAll().slice(0, nr);
    elts.toggleClass('hide');
  }
}

///////////////////////////////////////////////////////////////////////////////
// person in modal anzeigen von schulverwaltungs#index
///////////////////////////////////////////////////////////////////////////////

// show person in modal
window.show_sv_person_in_modal = function show_sv_person_in_modal(undeletable_person_id){
  if (undeletable_person_id){
    var path = "/schulverwaltungs/show_sv_person_in_modal";
    $.ajax({
      type: "GET",
      url: path,
      dataType: "script",
      data: { undeletable_person_id: undeletable_person_id }
    });
  } else {
    alert("Die Person kann nicht angezeigt werden. Bitte probieren Sie es nochmal, " +
      "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 73)")
  }
}
