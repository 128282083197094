// alle grading-änderungen verwerfen
// benennung der vars teilweise übernommen von sitzungs
window.grading_markierung_entfernen = function grading_markierung_entfernen(e) {
  var member_checkboxes; // checkbox elements for new member status
  var match_end_side = get_match_end_side(window.match_start_side);

  member_checkboxes = document.getElementsByClassName("match-member match-" + match_end_side);
  $(member_checkboxes).removeAttr('data-ordinal-grade');
  $(member_checkboxes).children('.multi-match-checkbox.grading.neutral').removeClass('hide');
  $(member_checkboxes).children('.multi-match-checkbox.grading.checked').addClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// teilnahme-änderungen speichern
// vars teilweise übernommen von sitzungs
window.grading_teilnahme_speichern = function grading_teilnahme_speichern(e) {

  var membership_elements; // elements holding membership info
  var join_data = []; // array will hold id's of joining members
  var leave_data = []; // array will hold id's of leaving members
  var change_data = []; // array will hold id's of changed members
  var match_end_side = get_match_end_side(window.match_start_side);

  membership_elements = $(".match-member.match-" + match_end_side + ":not(.hide)");

  var current_ordinal_grade;
  var new_ordinal_grade;

  // add ids to join and leave lists
  $(membership_elements).each(function(){
    current_ordinal_grade = $(this).attr('data-ordinal-grade');
    new_ordinal_grade = $(this).find('.checked:not(.hide)').first().attr('data-ordinal-grade');

    if (current_ordinal_grade && !new_ordinal_grade){
      leave_data.push({
        pf_noteable_id: $(this).attr("data-id"),
        pf_noteable_type: $(this).attr("data-pfn-type"),
        ordinal_grade: null
      });
    } else if (!current_ordinal_grade && new_ordinal_grade){
      join_data.push({
        pf_noteable_id: $(this).attr("data-id"),
        pf_noteable_type: $(this).attr("data-pfn-type"),
        ordinal_grade: new_ordinal_grade
      });
    } else if (current_ordinal_grade && new_ordinal_grade &&
        current_ordinal_grade != new_ordinal_grade){
      change_data.push({
        pf_noteable_id: $(this).attr("data-id"),
        pf_noteable_type: $(this).attr("data-pfn-type"),
        ordinal_grade: new_ordinal_grade
      });
    }
  });

  if (join_data.length > 0 || leave_data.length || change_data.length > 0 && window.match_table) {

    var path = "/" + window.match_table + "/update_matches";

    $.ajax({
      type: "GET",
      url: path,
      dataType: "script",
      data: {id: window.begin, table1: window.table1, table2: window.table2, match_table: window.match_table,
        join_data: JSON.stringify(join_data), leave_data:JSON.stringify(leave_data),
        change_data:JSON.stringify(change_data), match_start_side: window.match_start_side,
        match_end_side: match_end_side }
    });
  } else {
    $(e.target).closest('.grading-speichern').removeClass('disabled');
  }
  return false; // don't follow html link
}
