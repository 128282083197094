// scrolls element into view with offset.
// arguments: offset is scroll offset from top in px,
// duration is duration of animation in ms.
window.scroll_into_view = function scroll_into_view(elt, scroll_parent_selector, offset, duration = 10) {
  const scroll_parent = $(elt).closest(scroll_parent_selector);
  var elt_offset = $(elt).position().top - scroll_parent.position().top;
  var current_scroll = scroll_parent.scrollTop();

  scroll_parent.animate({scrollTop: elt_offset + offset + current_scroll}, duration);
}

// animated highlight on element. delay is an integer which defines when
// animation will start. duration is animation in or out duration (i.e. half
// of total animation duration) in ms.
// the function adds and then removes a css class.
window.highlight_elt = function highlight_elt(elt, delay = 0, duration = 2000) {
  setTimeout(function(){
    $(elt).addClass('seabird-color-highlight2 seabird-tone-down-4 seabird-lighten-2a')
      .delay(duration).queue(function () {
        $(this).removeClass('seabird-color-highlight2 seabird-tone-down-4 seabird-lighten-2a');
        $(this).dequeue();
    });
  }, delay * 0.8 * duration)
}

window.scroll_into_view_when_loaded = function scroll_into_view_when_loaded(mutations, observer, elt) {
  var length = mutations.length;
  var mutation;

  for (var i = 0; i < length; i++) {
    mutation = mutations[i];
    if (mutation.type == 'childList') {
      var anchor = elt.getAttribute('data-anchor');
      var highlight = elt.getAttribute('data-highlight');
      var scroll_to_elt = elt.querySelector('#' + anchor);

      // setTimeout(function(){
        scroll_into_view(scroll_to_elt, '.slplaner-col-content-column', -135, 10)

        if (highlight == 'true') {
          highlight_elt(scroll_to_elt.querySelector('div.collapsible-header'));
        }
        observer.disconnect();
        i = length;
      // }, 0);
    }
  };
}
