import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const collapsibleHeader = this.element.querySelector('.collapsible-header');

    if (collapsibleHeader) {
      this.highlightElement(collapsibleHeader);
    }
  }
  
  highlightElement(element, delay = 0, duration = 2000) {
    setTimeout(() => {
      element.classList.add('seabird-color-highlight2', 'seabird-tone-down-4', 'seabird-lighten-2a');
      setTimeout(() => {
        element.classList.remove('seabird-color-highlight2', 'seabird-tone-down-4', 'seabird-lighten-2a');
      }, duration);
    }, delay * 0.8 * duration);
  }

}
