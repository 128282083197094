window.initialize_collapsibles = function initialize_collapsibles() {
  M.Collapsible.init($('.collapsible:not(.no-materialize-initialize)'))
  M.Collapsible.init($('.collapsible.expandable'), { accordion: false })
  initialize_collapsible_remotes();
}

document.addEventListener('turbo:frame-load', function (event) {
  var frame = event.target;
  if (frame.classList.contains('turbo-frame-initialize-collapsibles')) {
    initialize_collapsibles();
  }
});  
