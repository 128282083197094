import { setCookie } from '../utils/cookies';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', function () {
      let set_at = new Date(); 
      setCookie('slplaner_dse', 'consented_' + set_at, 365); 
    });
  }
}