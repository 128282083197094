import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.instance = M.Dropdown.init(this.element,
      {
        constrainWidth: false,
        coverTrigger: false,
        closeOnClick: true
      }
    )
  }

  disconnect() {
    this.instance.destroy()
  }
}
