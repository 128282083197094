import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.boundRemoveClassOnDescendants = this.removeClassOnDescendants.bind(this);
  } 

  connect() {
    document.addEventListener('mouseup', this.boundRemoveClassOnDescendants);
    document.addEventListener('touchend', this.boundRemoveClassOnDescendants);
  }

  disconnect() {
    document.removeEventListener('mouseup', this.boundRemoveClassOnDescendants);
    document.removeEventListener('touchend', this.boundRemoveClassOnDescendants);
  }
    
  removeClassOnDescendants(event) {
    if (!this.element.contains(event.target) && !event.target.closest('#page-modal, .modal-overlay')) {
      let remove_class = this.element.dataset.removeClassOnClickOutsideClassName;
      let remove_on = this.element.dataset.removeClassOnClickOutsideRemoveOnSelector;
      this.element.querySelectorAll(remove_on).forEach(elt => elt.classList.remove(remove_class));
    }
  }

}
