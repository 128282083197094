window.sj_changeover_initializers = function sj_changeover_initializers(){
  $('#sj-changeover-false').on('click', function(){
    $('#sj-changeover-options option').prop('selected', false);
  });
  $('#sj-changeover-options select').on('change', function(){
    if (!this.value){
      $('#sj-changeover-false input').prop('checked', true);
      $('#sj-changeover-true input').prop('checked', false);
    } else {
      $('#sj-changeover-false input').prop('checked', false);
      $('#sj-changeover-true input').prop('checked', true);
    }
  });
};
