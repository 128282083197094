// refreshes (re-displays) one sidenav kpi value.
window.refresh_sidenav_kpi = function refresh_sidenav_kpi(element_id, kpi_text, class_name){
  // get element and its class list
  var element = $('#' + element_id);
  if (element[0]) {
    var existing_class_names = element[0].classList;
    if (kpi_text){
      element.text(kpi_text);
      // check if color needs to change. if yes, remove previous and add
      // new color class.
      if(!(existing_class_names.contains(class_name))){
        existing_class_names.forEach(function(value){
          if (value.includes("-text")){
            element.removeClass(value);
          }
        });
        element.addClass(class_name);
      }
    } else {
      element.text('');
      // remove text color class(es)
      existing_class_names.forEach(function(value){
        if (value.includes("-text")){
          element.removeClass(value);
        }
      });
    }
  }
}
