const initialize_dropdowns = () => {
  M.Dropdown.init($('.dropdown-trigger'), {coverTrigger: false});
  M.Dropdown.init($('.profile-dropdown'), {constrainWidth: false});
  M.Dropdown.init($('.navbar-button-dropdown'), {
    constrainWidth: false, coverTrigger: false, closeOnClick: true
  });
  M.Dropdown.init($('.navbar-button-dropdown-right-aligned'), {
    constrainWidth: false, coverTrigger: false, closeOnClick: true, alignment: 'right'
  });
}

export default initialize_dropdowns
