import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"
import Sortable, { MultiDrag } from 'sortablejs';
Sortable.mount(new MultiDrag());
// import { Sortable, MultiDrag } from 'sortablejs';
// import Sortable from "sortablejs"

export default class extends Controller {

  initialize() {
    this.boundCustomDeselect = this.customDeselect.bind(this);
  } 

  connect() {

    console.log("DEBUG drag connected");

    this.sortable = Sortable.create(this.element, {
      animation: 150,
      sort: this.element.dataset.dragSort || false,
      draggable: this.element.dataset.dragDraggable,
      multiDrag: this.element.dataset.dragMulti || false,
      avoidImplicitDeselect: true,
      selectedClass: this.element.dataset.dragSelectedClass || "drag-selected",
      chosenClass: this.element.dataset.dragChosenClass || "sortable-chosen",
      onEnd: this.end.bind(this),
      onAdd: function (event) {
        console.log("DEBUG onAdd");
      },
      onMove: function (event) {
        console.log("DEBUG onMove");

        if (event.from.dataset.dragPreventOverlap == "true") {

          let selected_elts = document.querySelectorAll(".sortable-chosen, .drag-selected");
          let target_list_elts = event.to.querySelectorAll(".tt-standard-cell");

          if (selected_elts && target_list_elts) {
            function overlap(elt, other_elt) {
              return parseInt(elt.dataset.wdIndex) == parseInt(other_elt.dataset.wdIndex) &&
                parseInt(elt.dataset.startInMin) < parseInt(other_elt.dataset.endInMin) &&
                parseInt(elt.dataset.endInMin) > parseInt(other_elt.dataset.startInMin)
            }

            // returns false if there is overlap between any dragged element
            // and any element in the target list -> drop is not allowed in 
            // this case.
            if (Array.from(selected_elts).some(function (elt) {
              return Array.from(target_list_elts).some(function (target_list_elt) { return overlap(target_list_elt, elt); })
            })) { return false; }
          }
        }
      },
      group: {
        name: this.element.dataset.dragGroupName,
        pull: function (to, from) {
          return from.el.dataset.dragPull || false;
        },
        put: true,
        revertClone: function (to, from) {
          return from.el.dataset.dragPull == 'clone';
        },
      }
    })

    if (this.sortable.el.classList.contains("time-table")) {
      document.addEventListener('mouseup', this.boundCustomDeselect);
      document.addEventListener('touchend', this.boundCustomDeselect);
    }
  }

  disconnect() {
    this.sortable.destroy();
    document.removeEventListener('mouseup', this.boundCustomDeselect);
    document.removeEventListener('touchend', this.boundCustomDeselect);
  }

  end(event) {
    console.log('DEBUG drag ended')

    if (event.to == event.from) {
      return false;
    }

    console.log("elements dropped");

    // console.log(event.from);  // previous list
    // console.log(event.to);    // target list
    // console.log(event.from.dataset);
    // console.log(event.to.dataset);

    let single_elt = event.item; 
    let multi_elts = event.to.querySelectorAll(".drag-selected");

    // console.log(single_elt);
    // console.log(multi_elts);

    let from_table_type = event.from.dataset.timeTableableType;
    let to_table_type = event.to.dataset.timeTableableType;
    let from_table_id = event.from.dataset.timeTableableId;
    let to_table_id = event.to.dataset.timeTableableId;
    let tt_start_date = event.from.dataset.ttStartDate;
    let tt_end_date = event.from.dataset.ttEndDate;
    let grid_cells_data = [];
    
    if (multi_elts.length > 0) {
      grid_cells_data = Array.from(multi_elts).map(function (elt) {
        return { tt_element_ids: Array.from(JSON.parse(elt.dataset.ttElementIds)) };
      });
    } else if (single_elt) {
      grid_cells_data = [{ tt_element_ids: JSON.parse(single_elt.dataset.ttElementIds) || [] }];
    }

    // if (multi_elts.length > 0) {
    //   tt_element_ids = Array.from(multi_elts).map(function (elt) {
    //     return Array.from(JSON.parse(elt.dataset.ttElementIds));
    //   }).flat();
    // } else if (single_elt) {
    //   tt_element_ids = JSON.parse(single_elt.dataset.ttElementIds) || [];
    // }

    let data = new FormData();
    data.append("grid_cells_data", JSON.stringify(grid_cells_data));
    data.append("from_table_type", from_table_type);
    data.append("to_table_type", to_table_type);
    data.append("from_table_id", from_table_id);
    data.append("tt_start_date", tt_start_date);
    data.append("tt_end_date", tt_end_date);
    data.append("to_table_id", to_table_id);

    // data.forEach((value, key) => {
    //     console.log(`${key}: ${value}`);
    // }); 

    Rails.ajax({
      url: "tt_elements/new_tt_substitutions",
      type: "POST",
      data: data
    })
  }

  customDeselect(event) {
    if (!event.target.closest(this.element.dataset.dragDraggable)) {
      this.sortable.multiDrag._deselectMultiDrag();
    }
  }

  // customDeselect(event) {
  //   if (!this.sortable.el.contains(event.target) && !event.target.closest('#page-modal, .modal-overlay')) {
  //     this.sortable.multiDrag._deselectMultiDrag();
  //   }
  // }  

  reactivate() {
    const elts = document.querySelectorAll(".time-table[data-time-tableable-type='Vertretung'] .tt-standard-cell.tt-edit-selected");
    elts.forEach(function (elt) {
      Sortable.utils.select(elt);
    });
  }

}