import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    expandable: Boolean, 
    emptyTurboFrameOnClose: Boolean
  }

  connect() {    
    const options = this.expandableValue ? { accordion: false } : {}
    if (this.emptyTurboFrameOnCloseValue) {

      options.onOpenStart = function (e) {
        const collapsible_body = e.querySelector('.collapsible-body');
        const turboFrame = collapsible_body.querySelector('turbo-frame');

        if (turboFrame) {
          turboFrame.reload();
        }
      }

      options.onCloseEnd = function (e) {
        const collapsible_body = e.querySelector('.collapsible-body');
        const turboFrame = collapsible_body.querySelector('turbo-frame');

        if (turboFrame) {
          turboFrame.innerHTML = '';

          if (!turboFrame.hasAttribute("src")) {
            if ('turboFrameSrcValue' in turboFrame.dataset) {
              turboFrame.setAttribute("src", turboFrame.dataset.turboFrameSrcValue); 
              delete turboFrame.dataset.turboFrameSrcValue;
            } 
          }         

        }
      }

    }

    this.instance = M.Collapsible.init(this.element, options)
  }

  disconnect() {
    this.instance.destroy()
  }
}
