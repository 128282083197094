import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.boundRemoveClassOnRemovees = this.removeClassOnRemovees.bind(this);

    // class to remove
    this.removeClass = this.element.dataset.reciprocalRemoveClassName.split(' '); 

    // selectors to trigger removal of class.
    // first two are mandatory, third and fourth are optional.
    this.selector1 = this.element.dataset.reciprocalRemoveClassSelector1;
    this.selector2 = this.element.dataset.reciprocalRemoveClassSelector2;
    this.selector3 = this.element.dataset.reciprocalRemoveClassSelector3;
    this.selector4 = this.element.dataset.reciprocalRemoveClassSelector4;
  } 

  connect() {
    document.addEventListener('mouseup', this.boundRemoveClassOnRemovees);
    document.addEventListener('touchend', this.boundRemoveClassOnRemovees);
  }

  disconnect() {
    document.removeEventListener('mouseup', this.boundRemoveClassOnRemovees);
    document.removeEventListener('touchend', this.boundRemoveClassOnRemovees);
  }
    
  removeClassOnRemovees(event) {
    if (event.target.closest(this.selector1)) {
      let removees2 = this.element.querySelectorAll(this.selector2);
      removees2.forEach(elt => elt.classList.remove(...this.removeClass));

      if (this.selector3) {
        let removees3 = this.element.querySelectorAll(this.selector3);
        removees3.forEach(elt => elt.classList.remove(...this.removeClass));
      }

      if (this.selector4) {
        let removees4 = this.element.querySelectorAll(this.selector4);
        removees4.forEach(elt => elt.classList.remove(...this.removeClass));
      }

    } else if (event.target.closest(this.selector2)) {
      let removees1 = this.element.querySelectorAll(this.selector1);
      removees1.forEach(elt => elt.classList.remove(...this.removeClass));

      if (this.selector3) {
        let removees3 = this.element.querySelectorAll(this.selector3);
        removees3.forEach(elt => elt.classList.remove(...this.removeClass));
      }

      if (this.selector4) {
        let removees4 = this.element.querySelectorAll(this.selector4);
        removees4.forEach(elt => elt.classList.remove(...this.removeClass));
      }
      
    } else if (this.selector3 && event.target.closest(this.selector3)) {
      let removees1 = this.element.querySelectorAll(this.selector1);
      removees1.forEach(elt => elt.classList.remove(...this.removeClass));

      let removees2 = this.element.querySelectorAll(this.selector2);
      removees2.forEach(elt => elt.classList.remove(...this.removeClass));

      if (this.selector4) {
        let removees4 = this.element.querySelectorAll(this.selector4);
        removees4.forEach(elt => elt.classList.remove(...this.removeClass));
      }  
      
    } else if (this.selector4 && event.target.closest(this.selector4)) {
      let removees1 = this.element.querySelectorAll(this.selector1);
      removees1.forEach(elt => elt.classList.remove(...this.removeClass));

      let removees2 = this.element.querySelectorAll(this.selector2);
      removees2.forEach(elt => elt.classList.remove(...this.removeClass));

      if (this.selector3) {
        let removees3 = this.element.querySelectorAll(this.selector3);
        removees3.forEach(elt => elt.classList.remove(...this.removeClass));
      }      
    }

  }

}
