export function phone_modal(name, nr, name2, nr2){
  var elem = $("#landing-page-phone-modal");
  var append_element = elem.children(".modal-content").first();

  // empty modal element from previous content, if any
  $(append_element).empty();

  var content = name + "<br>" + "<a href='tel:+41 " + nr.substr(1) + "' class='noref'>Tel " +
    nr + "</a>";

  if (name2 && nr2){
    content = content + "<br><br>" + name2 + "<br>" + "<a href='tel:+41 " +
      nr2.substr(1) + "' class='noref'>Tel " + nr2 + "</a>";
  }

  content = "<div>" + content + "</div>";

  append_element.html(content);

  var instance = M.Modal.getInstance(elem);
  instance.open();
}