import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  replace(event) {
    event.preventDefault();
    event.stopPropagation();

    const xhr = event.detail[2];
    const response = xhr.responseText;
    this.containerTarget.outerHTML = response;
  }
}
