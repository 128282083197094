// # Place all the behaviors and hooks related to the matching controller here.
// # All this logic will automatically be available in application.js.
// # You can use CoffeeScript in this file: http://coffeescript.org/

///////////////////////////////////////////////////////////////////////////////
// functions for matching sitzungs - and other resources - to people in
// two_column view multi-match
///////////////////////////////////////////////////////////////////////////////

// alle als teilnehmer markieren
window.teilnahme_alle = function teilnahme_alle(e) {
  var match_end_side = get_match_end_side(window.match_start_side);
  var member_checkboxes = get_sitzungs_member_checkboxes(match_end_side);

  $(member_checkboxes).attr('new-member', "true");
  $(member_checkboxes).children('.match-member-new.neutral').addClass('hide');
  $(member_checkboxes).children('.match-member-new.checked').removeClass('hide');
  $(member_checkboxes).children('.match-member-new.unchecked').addClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// alle als nicht-teilnehmer markieren
window.teilnahme_keine = function teilnahme_keine(e) {
  var match_end_side = get_match_end_side(window.match_start_side);
  var member_checkboxes = get_sitzungs_member_checkboxes(match_end_side);

  $(member_checkboxes).attr('new-member', "false");
  $(member_checkboxes).children('.match-member-new.neutral').addClass('hide');
  $(member_checkboxes).children('.match-member-new.checked').addClass('hide');
  $(member_checkboxes).children('.match-member-new.unchecked').removeClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// enable selection of a "gleich wie" element to clone
window.gleich_wie = function gleich_wie(e) {
  var gleich_wie_button_icon = $(e.target)
    .closest('.match-button-li.gleich-wie.match-' + window.match_start_side)
    .find('i.material-icons');

  // if gleich_wie_button is already active, reset and return
  if (gleich_wie_button_icon.hasClass('orange-text')){
    gleich_wie_reset();
    return;
  }

  // highlight gleich_wie_button
  gleich_wie_button_icon.addClass('orange-text text-accent4');

  // highlight starts, remove tooltips, and change click listener
  var starts = $(".match-link.match-" + window.match_start_side).not(window.start_elt);
  $(starts).each(function(){
    toggle_match_link_appearance($(this)[0], "active");
    $(this).off();
    $(this).on('click', function(e){
      e.stopPropagation();
      gleich_wie_markieren(e);
    });
  });

  return false; // don't follow html link, i.e. don't reload page
}

// return gleich_wie elements to normal appearance and function
window.gleich_wie_reset = function gleich_wie_reset(){
  var gleich_wie_button = $(".match-button-li.gleich-wie.match-" + window.match_start_side);
  gleich_wie_button.find('i.material-icons').removeClass('orange-text text-accent4');

  var starts = $(".match-link.match-" + window.match_start_side).not(window.start_elt);
  $(starts).each(function(){
    toggle_match_link_appearance($(this)[0], "inactive");
    $(this).off();
    $(this).on('click', function(e){
      e.stopPropagation();
      multi_match_two_col_clicks(e);
    });
  });
}

// set member_new elements to positions corresponding to chosen
// "gleich wie" element
window.gleich_wie_markieren = function gleich_wie_markieren(e) {
  // return gleich_wie elements to normal appearance and function
  gleich_wie_reset()

  // display membership data
  var controller = document.querySelector("meta[name = 'psj']").getAttribute('controller');
  var path = "/" + controller + "/get_match_data";
  var match_end_side = get_match_end_side(window.match_start_side);
  var gleich_wie_id = e.target.getAttribute("data-id");

  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: {id: gleich_wie_id, table1: window.table1, table2: window.table2, match_table: window.match_table,
      match_start_side: window.match_start_side, match_end_side: match_end_side,
      mode: "gleich_wie"}
  });

  return false; // don't follow html link, i.e. don't reload page
}

// toggle through new_element states
window.member_new_markieren = function member_new_markieren(e) {
  var membership_element = e.target.closest('li');
  var current_member_status = $(membership_element).attr('current-member'); // boolean
  var new_member_status = $(membership_element).attr('new-member') || "neutral"; // true, false or "neutral"
  var change_to;
  var checkbox;

  if (current_member_status == "true"){
    change_to = (new_member_status == "neutral")? "false" : "neutral";
    checkbox = (new_member_status == "neutral")? "unchecked" : "neutral";
  } else {
    change_to = (new_member_status == "neutral")? "true" : "neutral";
    checkbox = (new_member_status == "neutral")? "checked" : "neutral";
  }

  $(membership_element).attr('new-member', change_to);
  $(membership_element).children('.match-member-new:not(.' + checkbox + ')').addClass('hide');
  $(membership_element).children('.match-member-new.' + checkbox).removeClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// alle teilnahme-änderungen verwerfen
window.teilnahme_markierung_entfernen = function teilnahme_markierung_entfernen(e) {
  var member_checkboxes; // checkbox elements for new member status
  var match_end_side = get_match_end_side(window.match_start_side);

  member_checkboxes = document.getElementsByClassName("match-member match-" + match_end_side);
  $(member_checkboxes).attr('new-member', "neutral");
  $(member_checkboxes).children('.match-member-new.neutral').removeClass('hide');
  $(member_checkboxes).children('.match-member-new.checked').addClass('hide');
  $(member_checkboxes).children('.match-member-new.unchecked').addClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// teilnahme-änderungen speichern
window.teilnahme_speichern = function teilnahme_speichern(e) {
  window.join_ids = []; // array will hold id's of joining members
  window.leave_ids = []; // array will hold id's of leaving members
  var match_end_side = get_match_end_side(window.match_start_side);
  var is_current_member;
  var is_new_member;

  var membership_elements = get_sitzungs_member_checkboxes(match_end_side); // elements holding membership info

  // add ids to join and leave lists
  $(membership_elements).each(function(){
    is_current_member = $(this).attr('current-member');
    is_new_member = $(this).attr('new-member');

    if (is_current_member == "true" && is_new_member == "false"){
      window.leave_ids.push($(this).attr("data-id")) // add id to list
    } else if (is_current_member == "false" && is_new_member == "true") {
      window.join_ids.push($(this).attr("data-id")) // add id to list
    }
  });

  if (window.join_ids.length > 0 || window.leave_ids.length > 0 && window.match_table) {

    var path = "/" + window.match_table + "/update_matches";

    $.ajax({
      type: "GET",
      url: path,
      dataType: "script",
      data: {id: window.begin, table1: window.table1, table2: window.table2, match_table: window.match_table,
      join_ids: JSON.stringify(window.join_ids), leave_ids:JSON.stringify(window.leave_ids),
        match_start_side: window.match_start_side, match_end_side: match_end_side }
    });
  } else {
    $(e.target).closest('.teilnahme-speichern').removeClass('disabled');
  }
  return false; // don't follow html link
}

window.add_to_join_list = function add_to_join_list(element){
  element.classList.add("selected-color"); // change color
  window.join_ids.push(element.getAttribute("data-id")) // add person id to list
}

window.remove_from_join_list = function remove_from_join_list(element){
  element.classList.remove("selected-color"); // change color
  window.index = window.join_ids.indexOf(element.getAttribute("data-id"));
  if (window.index > -1) window.join_ids.splice(index, 1);
}

window.add_to_leave_list = function add_to_leave_list(element){
  element.classList.add("deselected-color"); // change color
  window.leave_ids.push(element.getAttribute("data-id")) // add person id to list
}

window.remove_from_leave_list = function remove_from_leave_list(element){
  element.classList.remove("deselected-color"); // change color
  window.index = window.leave_ids.indexOf(element.getAttribute("data-id"));
  if (window.index > -1) {
    window.leave_ids.splice(window.index, 1);
  }
}

///////////////////////////////////////////////////////////////////////////////
//// Various helpers
///////////////////////////////////////////////////////////////////////////////


// zeige dauer der sitzung an, sobald anzahl und std oder minuten eingegeben sind
window.dauer_sitzung_total = function dauer_sitzung_total() {

  window.flag = false;

  // dom elements to write sum totals to
  var std_elt = document.getElementById("std_sitzung_total");
  var min_elt = document.getElementById("min_sitzung_total");
  // values entered in duration fields. sanitize handles invalid cases and returns
  // useable value
  var anzahl = sanitize(document.getElementById("sitzung_anzahl").value);
  var std_praesenz = sanitize(document.getElementById("sitzung_std_praesenz").value);
  var min_praesenz = sanitize(document.getElementById("sitzung_min_praesenz").value);
  var std_vor = sanitize(document.getElementById("sitzung_std_vor").value);
  var min_vor = sanitize(document.getElementById("sitzung_min_vor").value);

  // flag if invalid value was entered. in this case set sum total to 0.
  if (window.flag) {
    window.total_std = 0;
    window.total_min = 0;
  // entries are valid, calculate sum total
  } else {
    window.total_in_min = anzahl * ((std_praesenz + std_vor) * 60 + min_praesenz + min_vor);
    window.total_std = Math.floor(window.total_in_min / 60);
    window.total_min = window.total_in_min - window.total_std * 60;
  }
  // display sum total
  std_elt.innerHTML = window.total_std;
  min_elt.innerHTML = window.total_min;
}

// convert string values to number. blank gets converted to 0. if result
// is not a nonzero integer, it is not a valid entry -> set flag,
// alert and return. otherwise, entry is valid -> return value.
window.sanitize = function sanitize(value) {
  value = Number(value);
  // if it's not a nonzero integer, flag, alert and return
  if (isNaN(value) || !Number.isInteger(value) || value < 0) {
    window.flag = true;
    alert("Ungültiger Wert bei Anzahl, Stunden oder Minuten der Sitzung.");
    return;
  }
  // valid entry, return value
  return value;
}

window.get_sitzungs_member_checkboxes = function get_sitzungs_member_checkboxes(match_end_side){
  if (match_end_side == 'right'){ // people, select visible elements only
    var member_checkboxes = $(".p-header-li:not(.hide) .match-member.match-" + match_end_side);
  } else {
    var member_checkboxes = $(".match-member.match-" + match_end_side);
  }
  return member_checkboxes;
}
