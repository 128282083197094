import {Controller} from "@hotwired/stimulus";
import isTesting from "../utils/isTesting";

export default class extends Controller {

  connect() {
    const testing = isTesting()

    const modalInstance = M.Modal.init(this.element,
      {
        inDuration: testing === null ? 350 : 0,
        outDuration: testing === null ? 250 : 0,
      })
    modalInstance.open()
  }

  close() {
    const modalInstance = M.Modal.getInstance(this.element)
    modalInstance.close()
    this.element.remove()
  }
}
