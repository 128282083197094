import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.instance = M.Tooltip.init(this.element,
      {
        enterDelay: this.element.dataset.tooltipEnterDelay || 200,
      }
    )
  }

  disconnect() {
    this.instance.destroy()
  }
}
