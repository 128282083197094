// functions on user interface for import_vorjahr on klasses

// get both parameters from form and pass to "klasses/import_vorjahr_check_validation",
// in order to remotely validate entries before form is submitted
// arguments: field = field this fct was called from, klasse_field_id is
// id prefix for this klasse's form elements
window.pre_check_import_vorjahr_klasse_record = function pre_check_import_vorjahr_klasse_record(field_type, field_id, field_value){

  // remove field specific ending on field_id
  var klasse_field_id = field_id.replace("-" + field_type, "");

  // get value of kt field if field_type is "name", and vice versa
  var other_field_value = document
    .getElementById(klasse_field_id + (field_type == "name" ? "-kt" : "-name"))
    .value;

  var path = "klasses/import_vorjahr_check_validation"
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: {
      klasse_field_id: klasse_field_id,
      field_type: field_type,
      field_value: field_value,
      other_field_value: other_field_value}
  });
}
