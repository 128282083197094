import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeListeners();
  }

  initializeListeners() {
    this.element.querySelector('#schule-info-edit-button').addEventListener('click', this.showEditFields.bind(this));
    this.element.querySelector('#schule-info-edit-beenden').addEventListener('click', this.hideEditFields.bind(this));
    this.element.querySelector('#schule-info-edit-speichern').addEventListener('click', this.submitForm.bind(this));
  }

  showEditFields() {
    this.element.querySelectorAll('.schule-info-button-li').forEach(el => el.classList.remove('hide'));
    this.element.querySelector('#schule-info-static-field').classList.add('hide');
    this.element.querySelector('#schule-info-form-field').classList.remove('hide');
  }

  hideEditFields() {
    this.element.querySelectorAll('.schule-info-button-li').forEach(el => el.classList.add('hide'));
    this.element.querySelector('#schule-info-static-field').classList.remove('hide');
    this.element.querySelector('#schule-info-form-field').classList.add('hide');
  }

  submitForm() {
    const submitButton = this.element.querySelector('#schule-info-form-submit-button');
    submitButton.click();
    submitButton.setAttribute('disabled', 'disabled');
    this.element.querySelectorAll('.schule-info-button-li').forEach(el => el.classList.add('hide'));
  }

}
