import { getCookie } from "../utils/cookies";

require("../on_page_load_inits/contract_expiration_notices")
require("../on_page_load_inits/collapsible_remote_initializers")
require("../on_page_load_inits/initialize_collapsibles")
require("../on_page_load_inits/initialize_dropdowns")
require("../on_page_load_inits/initialize_modals")
require("../on_page_load_inits/registration_initializers")
require("../on_page_load_inits/schulverwaltung_initializers")
require("../on_page_load_inits/scroll_on_keydown")
import initialize_dropdowns from "../on_page_load_inits/initialize_dropdowns";

$(document).ready(on_page_load_initializers);

function on_page_load_initializers() {
  window.Slplaner = {}; // name space for global vars

  var controller = document.querySelector("meta[name = 'psj']").getAttribute('controller');
  var action = document.querySelector("meta[name = 'psj']").getAttribute('action');
  var match_actions = get_match_actions();
  var freigabe_actions = get_freigabe_actions();

  initialize_collapsibles();
  initialize_dropdowns();
  initialize_scroll_on_keydown();
  initialize_regular_modals();
  initialize_cn_modal();

  M.FormSelect.init($('select'), {
    dropdownOptions: {
      container: document.body
    }
  });
  M.ScrollSpy.init($('.scrollspy'));

  $(document).on('click', '#toast-container .toast', function() {
    $(this).remove();
  });

  $('.disable-after-click').click(function(e){
    $(e.target).closest('a').css('pointer-events','none');
    $(e.target).closest('a').css('cursor','default');
  });

  $(document).on('click', '#stv_signup_sl', function(e) {
    e.preventDefault();
    $.ajax({
      url: '/vertretungs/stv_signup',
      data: {view_version: 'sl'},
      method: 'GET',
      dataType: 'script' 
    });
  });  

  $(document).on('click', '#stv_signup_sv', function(e) {
    e.preventDefault();
    $.ajax({
      url: '/vertretung_versions/stv_signup',
      data: {view_version: 'sv'},
      method: 'GET',
      dataType: 'script' 
    });
  });  

  /////////////////////////////////////////////////////////////////////////////
  // open landing page modal
  /////////////////////////////////////////////////////////////////////////////

  if (["sessions", "registrations", "test_mab_tools"].includes(controller)){
    var elem = $("#landing-page-modal.open-landing-page-modal");
    if ($(elem).length) {
      let dse_cookie = getCookie('slplaner_dse');
      if (dse_cookie && dse_cookie.startsWith('consented')) {
        var instance = M.Modal.getInstance(elem);
        instance.open();
        $(elem).find("[data-focused='true']").focus();
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // contract expiration notices and denial of service because of missing
  // contract
  /////////////////////////////////////////////////////////////////////////////

  show_contract_expiration_notices(controller, action);

  /////////////////////////////////////////////////////////////////////////////
  // onboarding
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "schules" && action == "index"){
    $('#user-create-demo-schule').click(function(e){
      var elts = document.querySelectorAll('.profile-dropdown').forEach(function(e){
        M.Dropdown.getInstance(e).destroy();
      });

      $('a, #create-new-schule-button').css({"pointer-events": "none", "cursor": "default"});
      $('.bubble-highlight-container').fadeOut();
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // scroll_to
  /////////////////////////////////////////////////////////////////////////////

  $('li.scroll-to-record')
    .each(function() {
      var elt = $(this).find('.collapsible-header').first();

      scroll_into_view($(elt), '.slplaner-col-content-column', -135);

      if ($(this).hasClass('scroll-to-highlight')) {
        highlight_elt($(elt));
      }
  });

  $('table.slplaner-table tr.scroll-to-record')
    .each(function() {
      scroll_into_view($(this),
      '.slplaner-scrollable-table-container-inside, .slplaner-col-content-column',
      -50);

      if ($(this).hasClass('scroll-to-highlight')) {
        highlight_elt($(this));
      }
  });

  if (  (controller == "ressourcentyps" && action == "index") ||
        (action == "interactive_index" && controller == "schulverwaltungs") ){

    // anstellungstyp collapsible header in col with ressourcentyp elt
    // that needs to be scrolled to
    var elt = document.querySelector('.scroll-to-active[data-scroll-to-col="true"]');

    if (elt){
      var observation_target = elt.querySelector('.collapsible-body.collapsible-remote')
      var config = { childList: true };
      var observer = new MutationObserver(function(mutations, obs) {
        scroll_into_view_when_loaded(mutations, observer, elt);
      });

      observer.observe(observation_target, config);
      $(elt).find('.collapsible-header').trigger("click");
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // assign resources by matching
  /////////////////////////////////////////////////////////////////////////////

  $('.match-link:not(.multi-match)').click(function(e){
    e.stopPropagation();
    match_two_col_clicks(e);
  });

  $('.match-link.multi-match').click(function(e){
    e.stopPropagation();
    multi_match_two_col_clicks(e);
  });

  $('.gleich-wie').on('click', '*', function(e){
    e.stopPropagation();
    gleich_wie(e);
  });

  $('.teilnahme-alle').on('click', '*', function(e){
    e.stopPropagation();
    teilnahme_alle(e);
  });

  $('.teilnahme-keine').on('click', '*', function(e){
    e.stopPropagation();
    teilnahme_keine(e);
  });

  $('.teilnahme-markierung-entfernen').on('click', '*', function(e){
    e.stopPropagation();
    teilnahme_markierung_entfernen(e);
  });

  $('.teilnahme-speichern').on('click', '*', function(e){
    e.stopPropagation();
    if (!$(e.target).closest('.teilnahme-speichern').hasClass('disabled')){
      $(e.target).closest('.teilnahme-speichern').addClass('disabled');
      teilnahme_speichern(e);
    }
  });

  $('.match-member-current').on('click', function(e){
    e.stopPropagation();
  });

  $('.match-member-new').on('click', function(e){
    e.stopPropagation();
    member_new_markieren(e);
  });

  if (controller == "pfile_events" && action == "index"){

    $('.multi-match-checkbox.grading').on('click', function(e){
      e.stopPropagation();
      grading_radio_checkboxes_click(e);
    });

    $('.grading-markierung-entfernen').on('click', '*', function(e){
      e.stopPropagation();
      grading_markierung_entfernen(e);
    });

    $('.grading-speichern').on('click', '*', function(e){
      e.stopPropagation();

      if (!$(e.target).closest('.grading-speichern').hasClass('disabled')){
        $(e.target).closest('.grading-speichern').addClass('disabled');
        grading_teilnahme_speichern(e);
      }
    });
  }

  if (controller in match_actions && match_actions[controller].includes(action)) {
    // initialize global vars
    window.match_start_side = null; // side of two_col_layout clicked on
    window.begin = null; // database record id of start record
    window.start_elt = null; // start-element in dom
    window.table1 = null; // table on start side
    window.table2 = null; // table on end side
    window.match_table = null; // table combining start and end elements
    // reset match variables if clicked anywhere on page that is not
    // relevant for matching
    document.addEventListener("click", call_match_reset);
  }

  // actions for which match functions are to be activated
  function get_match_actions(){
    var match_actions = {
      amts: ["index"],
      anstellung_weiteres: ["index"],
      betreuungs: ["index"],
      klassenassistenzs: ["index"],
      klasses: ["index"],
      lektionen_solls: ["index", "index_klasse"],
      lektionen_weiteres: ["index"],
      pfile_events: ["index"],
      sitzungs: ["index"],
      sonderpaeds: ["index"],
      vertretungs: ["index"]
    };
    return match_actions;
  }

  /////////////////////////////////////////////////////////////////////////////
  // freigabe for schulverwaltung on people
  /////////////////////////////////////////////////////////////////////////////

  if (controller in freigabe_actions && freigabe_actions[controller].includes(action)) {
    // initialize global var
    window.freigabe_edit_on = false; // flag

    document.addEventListener("click", call_freigabe_reset);

    $('.freigabe-button').on('click', '*', function(e){
      e.stopPropagation();
      show_freigabe_elements();
    });

    $('.freigabe-alle').on('click', '*', function(e){
      e.stopPropagation();
      freigabe_alle();
    });

    $('.freigabe-keine').on('click', '*', function(e){
      e.stopPropagation();
      freigabe_keine();
    });

    $('.freigabe-markierung-entfernen').on('click', '*', function(e){
      e.stopPropagation();
      freigabe_markierung_entfernen();
    });

    $('.freigabe-speichern').on('click', '*', function(e){
      e.stopPropagation();
      freigabe_speichern();
    });

    $('.freigabe-current').on('click', function(e){
      e.stopPropagation();
    });

    $('.freigabe-new').on('click', function(e){
      e.stopPropagation();
      freigabe_new_markieren(e);
    });
  }

  // actions for which freigabe functions are to be activated
  function get_freigabe_actions(){
    var freigabe_actions = {
      amts: ["index"],
      anstellung_weiteres: ["index"],
      betreuungs: ["index"],
      klassenassistenzs: ["index"],
      klasses: ["index"],
      lektionen_solls: ["index", "index_klasse"],
      lektionen_weiteres: ["index"],
      sitzungs: ["index"],
      sonderpaeds: ["index"],
      people: ["index"]
    };
    return freigabe_actions;
  }

  /////////////////////////////////////////////////////////////////////////////
  // stop propagation if class "no-propagation" is assigned to element
  /////////////////////////////////////////////////////////////////////////////

  $('.no-propagation').click(function(e){
    e.stopPropagation();
  });


  /////////////////////////////////////////////////////////////////////////////
  // stop propagation on elements on touch devices, especially dropdown
  // elements
  /////////////////////////////////////////////////////////////////////////////

  $('li[id^="select-options"]').on('touchend', function (e) {
      e.stopPropagation();
  });

  $('ul[id="import-one-person-dropdown-content"]').on('touchend', function (e) {
      e.stopPropagation();
  });

  $('div[id="profile"], div[id="school_profile"]').on('touchend', function (e) {
      e.stopPropagation();
  });

  $('.no-propagation').on('touchend', function (e) {
      e.stopPropagation();
  });


  /////////////////////////////////////////////////////////////////////////////
  // schulverwaltung functions
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "schulverwaltungs" && action == "interactive_index"){
    interactive_sv_initializers();
  }

  if (controller == "schulverwaltungs" && ["index", "index_anstellungen"].includes(action)){
    sv_initializers(action);
  }

  /////////////////////////////////////////////////////////////////////////////
  // download zip file with all pv (pensenübersichten)
  /////////////////////////////////////////////////////////////////////////////

  // activate this on same pages as freigabe für schulverwaltung
  if ((controller in freigabe_actions && freigabe_actions[controller].includes(action)) ||
      (controller == "schulverwaltungs" &&
        ["interactive_index", "index", "index_anstellungen"].includes(action))){
    download_all_pv_listeners();
  }

  /////////////////////////////////////////////////////////////////////////////
  // people list functions
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "people" && action == "list"){

    var people_table = document.getElementById("people-list-table");

    // listener on bemerkungen swap button
    $('#bemerkungen-swap-button').on('click', function(){
      toggle_people_list_bemerkungen(people_table, "people_list_bemerkungen", 1);
    });

  }

  if (controller == "people" && action == "list_for_pfile_events_view"){

    var people_table = document.getElementById("people-list-table");

    // listener on bemerkungen swap button
    $('#bemerkungen-swap-button').on('click', function(){
      toggle_people_list_bemerkungen(people_table, "people_list_for_pfile_events_bemerkungen", 1);
    });

  }

  /////////////////////////////////////////////////////////////////////////////
  // schule settings, info, etc. also schule form submit button.
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "schules" && action == "show_schule_info"){

    $('#schule-info-edit-button').on('click', function(){
      $(".schule-info-button-li").removeClass('hide');
      $("#schule-info-static-field").addClass('hide');
      $("#schule-info-form-field").removeClass('hide');
    });

    $('#schule-info-edit-beenden').on('click', function(){
      $(".schule-info-button-li").addClass('hide');
      $("#schule-info-static-field").removeClass('hide');
      $("#schule-info-form-field").addClass('hide');
    });

    // add click event to speichern button: submit form
    $("#schule-info-edit-speichern").click(function(){
      $("#schule-info-form-submit-button").click();
      $("#schule-info-form-submit-button").attr("disabled","disabled");
      $(".schule-info-button-li").addClass('hide');
    });
  }

  if (controller == "schules"){
    activate_schule_submit_button_placeholder_listeners();
  }

  /////////////////////////////////////////////////////////////////////////////
  // show/hide von/bis fields on forms and initialize datepicker
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "betreuung_angebot_blocks" &&
      ["new", "create", "edit", "update"].includes(action)) {

    $('.hide-von-bis-fields').on('click', function(){
      $('#select-von-bis-fields').addClass('hide');
    });
    $('.show-von-bis-fields').on('click', function(){
      $('#select-von-bis-fields').removeClass('hide');
    });

    initialize_slplaner_datepicker($('#select-von-bis-fields'));

  } else if (controller == "people" && ["new", "create", "edit", "update"].includes(action)) {
    initialize_slplaner_datepicker($('#bg100_von_bis'));

  } else if (controller == "vertretungs" && ["new", "create", "edit", "update"].includes(action)) {
    initialize_slplaner_datepicker($('#vertretung-form'));
  }

  /////////////////////////////////////////////////////////////////////////////
  // show/hide sj-changeover fields on registrations edit
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "registrations" && ["edit", "update"].includes(action)) {
    sj_changeover_initializers();
  }

  /////////////////////////////////////////////////////////////////////////////
  // restrict file typs for uploads on faqs
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "faqs" && ["new", "edit"].includes(action)) {
    window.addEventListener("trix-file-accept", function(event) {
      const acceptedTypes = ['image/jpeg', 'image/png']
      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault()
        alert("Sie können nur Bilddateien hochladen.")
      }
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  // various
  /////////////////////////////////////////////////////////////////////////////

  if (controller == "sitzungs" && ["edit", "update"].includes(action)) {
    dauer_sitzung_total();
  }

  $('.import-vorjahr-navbar-button').on('click', function(e){
    var confirm_text = "Sind Sie sicher: DATEN AUS DEM VORJAHR IMPORTIEREN? " +
      "Bereits vorhandene Daten in diesem SJ werden nicht verändert und nicht überschrieben.";

    if (confirm(confirm_text)) {
      if ($(e.target).hasClass('import-vj-activate-col1-progress')) {
        $('#slplaner-two-col-content-left').scrollTop(0);
        $('.progress.col1-progress').removeClass("hide");
      } else if ($(e.target).hasClass('import-vj-activate-col2-progress')) {
        $('#slplaner-two-col-content-right').scrollTop(0);
        $('.progress.col2-progress').removeClass("hide");
      }
    } else {
      return false;
    }
  })

  $('.activate-progress').on('click', function(){
    $('.progress').removeClass("hide");
  })

  $('.activate-col1-progress').on('click', function(){
    $('#slplaner-two-col-content-left').scrollTop(0);
    $('.progress.col1-progress').removeClass("hide");
  })

  $('.activate-col2-progress').on('click', function(){
    $('#slplaner-two-col-content-right').scrollTop(0);
    $('.progress.col2-progress').removeClass("hide");
  })

}
