window.right_click_new_employments = function right_click_new_employments(event) {
  right_click_new_tt_elements(event, "/tt_elements/new_tt_employments");
}

window.right_click_edit_employments = function right_click_edit_employments(event) {
  right_click_edit_tt_elements(event, "/tt_elements/edit_tt_employments");
}

window.right_click_new_vertretungs = function right_click_new_vertretungs(event) {
  right_click_new_tt_elements(event, "/tt_elements/new_tt_vertretungs");
}

window.right_click_edit_vertretungs = function right_click_edit_vertretungs(event) {
  right_click_edit_tt_elements(event, "/tt_elements/edit_tt_vertretungs");
}

window.right_click_edit_substitutions = function right_click_edit_substitutions(event) {
  right_click_edit_tt_elements(event, "/tt_elements/edit_tt_substitutions");
}

////////////////////////////////////////////////////////////////////////////////
//// shared
////////////////////////////////////////////////////////////////////////////////

window.right_click_new_tt_elements = function right_click_new_tt_elements(event, url) {
  let caller_elt = event.target.closest(event.currentTarget.dataset.contextMenuElementsSelector);
  let table_elt = caller_elt.closest(".time-table"); 
  let time_tableable_type = table_elt.dataset.timeTableableType;
  let time_tableable_id = table_elt.dataset.timeTableableId;
  let tt_start_date = table_elt.dataset.ttStartDate;
  let tt_end_date = table_elt.dataset.ttEndDate;

  let selected_elts = table_elt.querySelectorAll(".tt-edit-selected"); 
  let grid_cells_data;

  if (Array.from(selected_elts).includes(caller_elt)) {
    grid_cells_data = Array.from(selected_elts).map(function (elt) {
      return {
        start_in_min: elt.dataset.startInMin,
        end_in_min: elt.dataset.endInMin,
        tt_weekday_index: elt.dataset.wdIndex,
        time_block_id: elt.dataset.timeBlockId,
        new_tt_element_empty_cell_dates: elt.dataset.datesString
      }
    });
  } else {
    grid_cells_data = [{
      start_in_min: caller_elt.dataset.startInMin,
      end_in_min: caller_elt.dataset.endInMin,
      tt_weekday_index: caller_elt.dataset.wdIndex,
      time_block_id: caller_elt.dataset.timeBlockId,
      new_tt_element_empty_cell_dates: caller_elt.dataset.datesString
    }]
  }

  let data = {
    time_tableable_type: time_tableable_type,
    time_tableable_id: time_tableable_id,
    tt_start_date: tt_start_date,
    tt_end_date: tt_end_date,
    grid_cells_data: JSON.stringify(grid_cells_data)
  }

  $.ajax({
    url: url,
    type: "GET",
    data: data
  })
}

window.right_click_edit_tt_elements = function right_click_edit_tt_elements(event, url) {
  let caller_elt = event.target.closest(event.currentTarget.dataset.contextMenuElementsSelector);
  let table_elt = caller_elt.closest(".time-table"); 
  let time_tableable_type = table_elt.dataset.timeTableableType;
  let time_tableable_id = table_elt.dataset.timeTableableId;
  let tt_start_date = table_elt.dataset.ttStartDate;
  let tt_end_date = table_elt.dataset.ttEndDate;

  let selected_elts = table_elt.querySelectorAll(".tt-edit-selected"); 
  let grid_cells_data;

  if (Array.from(selected_elts).includes(caller_elt)) {
    grid_cells_data = Array.from(selected_elts).map(function (elt) {
      return {
        tt_element_ids: Array.from(JSON.parse(elt.dataset.ttElementIds)),
      }
    });
  } else {
    grid_cells_data = [{
      tt_element_ids: Array.from(JSON.parse(caller_elt.dataset.ttElementIds)),
    }]
  }

  let data = {
    time_tableable_type: time_tableable_type,
    time_tableable_id: time_tableable_id,
    tt_start_date: tt_start_date,
    tt_end_date: tt_end_date,
    grid_cells_data: JSON.stringify(grid_cells_data), 
  }

  $.ajax({
    url: url,
    type: "GET",
    data: data
  })
}


