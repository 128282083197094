// set cookie with expiration time at the next upcoming midnight if exdays is
// given as 1, or incrementally at following midnights for greater exday values.
// cname = cookie name, cvalue = cookie value
export function setCookie(cname, cvalue, exdays) {
  var days = exdays > 1 ? Math.floor(exdays) - 1 : 0 ;
  var d = new Date();
  d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000))
  d.setHours(24, 0, 0, 0);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires;
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}