import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick.bind(this))
  }

  handleClick(event) {
    event.preventDefault()
    const path = this.element.getAttribute("data-path")

    $.ajax({
      type: "GET",
      url: path,
      dataType: "script"
    })
  }
}