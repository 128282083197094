import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["localUserSelect", "localUserFormFrame"]

  loadLocalUserForm() {
    if (this.localUserSelectTarget.value === "") {
      this.hideLocalUserFormFrame()
    } else {
      this.localUserFormFrameTarget.setAttribute("src", `/invitations/fetch_local_user_form?local_user_id=${this.localUserSelectTarget.value}`)
    }
  }

  hideLocalUserFormFrame() {
    this.localUserFormFrameTarget.removeAttribute("src")
    this.localUserFormFrameTarget.innerHTML = ""
  }
}
