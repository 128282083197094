// Setup polyfills for older browser versions
import "core-js/actual"

// Setup Appsignal
import appsignal from "./all/appsignal";
window.addEventListener("load", (_event) => {
  appsignal.getInstance()
})

// Turbo Rails
import "@hotwired/turbo-rails"
Turbo.session.drive = false
Turbo.StreamActions.redirect = function () {
  window.location = this.target;
};

// Stimulus controllers
import "./controllers"

// Rails UJS
require("@rails/ujs").start()

// Load jQuery
window.$ = window.JQuery = require("jquery");

// Load JavaScript needed for materialize-css
require("materialize-css/dist/js/materialize")

// Initialize application
require("./all/people")
require("./all/sitzungs")
require("./all/all")
require("./all/freigabes")
require("./all/two_col_match")
require("./all/import_vorjahr")
require("./all/navigation")
require("./all/refresh_sidenav_kpi")
require("./all/scroll_to")
require("./all/schules")
require("./all/utilities")
require("./all/slplaner_datepicker")
require("./all/test_mab_tool")
require("./all/one_time_password")
require("./all/grading")
require("./all/pfile_events")
require("./all/abc_nav")
require("./all/schulverwaltungs")
require("./all/slplaner_initializers")
require("./all/time_table")

import "trix"
import "@rails/actiontext"
