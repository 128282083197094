import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.boundScrollTo = this.scrollTo.bind(this);
  } 

  connect() {
    let scrollers = this.element.querySelectorAll('.scroller');

    scrollers.forEach(scroller => {
        scroller.addEventListener('click', this.boundScrollTo);
    });
  }

  disconnect() {
    let scrollers = this.element.querySelectorAll('.scroller');

    scrollers.forEach(scroller => {
        scroller.removeEventListener('click', this.boundScrollTo);
    });
  }
    
  scrollTo(event) {
    let target_id = event.currentTarget.dataset.scrollToTargetId;
    if (target_id) {
      let elt = document.querySelector("#" + target_id);
      if (elt) {
        let scroll_parent_selector =
          event.currentTarget.dataset.scrollToScrollParentSelector;
        let offset = parseInt(event.currentTarget.dataset.scrollToOffset);
        let duration = parseInt(event.currentTarget.dataset.scrollToDuration);

        scroll_into_view(elt, scroll_parent_selector, offset, duration);
      }
    }
  }

}