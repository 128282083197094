window.scroll_to_abc = function scroll_to_abc(event) {
  if (event.repeat || event.key === undefined) {
    return;
  }

  if (event.key.match(/^[a-zA-Z]{1}$/)) {
    // if mouse over abc-scrollable section

    // people
    if($(".scroll-p-on-keydown:hover").length > 0) {
      var elt = $("#p_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }

    // lektionen_solls
    } else if ($(".scroll-ls-on-keydown:hover").length > 0) {
      var elt = $("#ls_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }

    // amts
    } else if ($(".scroll-amt-on-keydown:hover").length > 0) {
      var elt = $("#amt_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }

    // schules
    } else if ($(".scroll-sch-on-keydown:hover").length > 0) {
      var elt = $("#sch_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }

    // users
    } else if ($(".scroll-u-on-keydown:hover").length > 0) {
      var elt = $("#u_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }

    // user_schule_people
    } else if ($(".scroll-usp-on-keydown:hover").length > 0) {
      var elt = $("#usp_index_" + event.key.toUpperCase());
      if (elt[0]) { scroll_into_view(elt, '.slplaner-col-content-column', -2); }
    }
    // invitations
    else if ($(".scroll-i-on-keydown:hover").length > 0) {
      var elt = $("#i_index_" + event.key.toUpperCase());
      if (elt[0]) {
        scroll_into_view(elt, '.slplaner-col-content-column', -2);
      }
    }
  }
}
