import isTesting from "../utils/isTesting";

window.initialize_regular_modals = function initialize_regular_modals() {
  const testing = isTesting()

  M.Modal.init(document.querySelectorAll('.modal:not(#cn-modal)'),
    {
      inDuration: testing === null ? 350 : 0,
      outDuration: testing === null ? 250 : 0,
    }
  );
}

// customer notification
window.initialize_cn_modal = function initialize_ch_modal() {
  var cn_modal = document.querySelector('#cn-modal');

  if (cn_modal) {
    cn_modal.classList.add('page-modal-large');
    const testing = isTesting()

    M.Modal.init(cn_modal,
      {
        inDuration: testing === null ? 350 : 0,
        outDuration: testing === null ? 250 : 0,
        onCloseEnd: function (e) {
          $(e).remove();
        }
      }
    );

    M.Modal.getInstance(cn_modal).open();
  }
}
