import {subscribe_to_download_all_pv_notifications_channel} from "../channels/download_all_pv_notifications_channel";

// listeners for download_all_pv buttons
window.download_all_pv_listeners = function download_all_pv_listeners(){

  // click on button to download all pv (pensenübersichten) as zip file,
  // case: no content available in unfiltered condition (i.e. over all records).
  $('.download-all-pv-button.no-content').on('click', function(e){
    no_content_message_for_download_all_no_records(this);
  });

  // click on button to download all pv (pensenübersichten) as zip file,
  // case: content available
  $('.download-all-pv-button:not(.no-content)').on('click', function(e){
    var li_elt = $(e.target).closest('li');

    if($(li_elt).hasClass('inactive')){
      M.toast({html: "Sobald Ihr Download beendet ist, können Sie einen weiteren starten."});
      return;
    }

    // inactivate buttons to prevent double clicks or triggering second
    // download before first is finished. there are two such
    // button elts on navbar, and two in dropdown for smaller screens.
    $('.download-all-pv-button').addClass('inactive');

    // get ids of filtered (i.e. visible) people. pdf's will only
    // be downloaded for these records.
    const upids = get_filtered_person_upids();

    // if filter is empty, restore button and return.
    // the case where pv are downloaded from people (not sv view) and current
    // filter has no people with freigabe, is not covered with
    // message to user. instead, user gets empty zip file. effort to check
    // for this case is not worth it right now, but maybe implement later.
    if (upids.length == 0) {
      no_content_message_for_download_all_empty_filter(this);
      $('.download-all-pv-button').removeClass('inactive');
      return;
    }

    // ID to identify dom elements and ActionCable channels associated with this
    // download request. leading letter is added to fulfill html syntax
    // requirement (id must start with a letter).
    var download_id = "a" + short_random_id();

    // long-lived toast will be used to display progress.
    // toast contains div with download_id (can't put this id directly
    // on toast since toast id is determined by materialize)
    Slplaner.download_all_pv_toast_duration = 240000;

    var toastHTML = '\
      <div id="' + download_id  + '">\
        <div class="download-all-pv-msg">Ihr Download wird vorbereitet. Dies dauert einen Moment.</div><br> \
        <div class="progress download-all-pv-progress">\
          <div class="indeterminate" style="background-color: #0099ff;"></div>\
        </div>\
      </div>';

    M.toast({
      classes: "download-all-pv-toast cancellation-notice",
      displayLength: Slplaner.download_all_pv_toast_duration,
      html: toastHTML
    })

    // re-activate buttons when toast is clicked.
    // serve cancellation notice if conditions are met.
    var toast = $('#' + download_id).closest('.download-all-pv-toast');

    $(toast).on("click",
      function(e){
        if($(this).hasClass('cancellation-notice') && !$(e.target).hasClass('zip-download-link')){
          M.toast({html: "Ihr Download wurde abgebrochen."});
        }

        $('.download-all-pv-button').removeClass('inactive');
      }
    );

    var url_to_create_pv_zipfile =
      $(e.target).closest('a').attr('data-data1');

    // this will trigger zip file creation on subscribed.
    subscribe_to_download_all_pv_notifications_channel(
      download_id,
      url_to_create_pv_zipfile,
      upids
    );
  });
}

window.unsubscribe_download_all_pv_channel = function unsubscribe_download_all_pv_channel(download_id) {

}


window.get_filtered_person_upids = function get_filtered_person_upids(){
  var p1 = $('.p-header-li:not(.hide)')
  var p2 = $('.svp-header-li:not(.hide), tr.svp-tr:not(.hide)');

  var upids1 = p1
    .map(function() {
      return $(this).attr('id') ? $(this).attr('id').slice(6) : null;
      })
    .get()

  var upids2 = p2
    .map(function() {
      return $(this).attr('id') ? $(this).attr('id').slice(9) : null;
      })
    .get()

  let upids = upids1.concat(upids2);

  // eliminate duplicate values
  upids = [...new Set(upids)];

  return upids;
}

window.no_content_message_for_download_all =
function no_content_message_for_download_all(
    elt,
    no_people_message,
    no_snapshots_message){

  if($(elt).hasClass("download-all-pv-snapshots")){
    M.toast({html: no_snapshots_message});
  } else {
    M.toast({html: no_people_message});
  }
}

window.no_content_message_for_download_all_no_records =
function no_content_message_for_download_all_no_records(elt){
  no_content_message_for_download_all(elt,
    'Es sind keine Personen vorhanden.',
    'Es sind keine Freigaben vorhanden.');
}

window.no_content_message_for_download_all_empty_filter =
function no_content_message_for_download_all_empty_filter(elt){
  no_content_message_for_download_all(elt,
    'In diesem Filter sind keine Personen vorhanden.',
    'In diesem Filter sind keine Freigaben vorhanden.');
}
