/////////////////////////////////////////////////////////////////////////////
// functions on open and close of collapsibles with class "collapsible-remote"
/////////////////////////////////////////////////////////////////////////////

// add dom elements with action buttons and data to collapsible body
// when section is opened (instead of
// pre-loading everything), and delete again when section is closed.
// to use, add class "collapsible-remote" to collapsible list item, and
// data-url (absolute or relative URL to page in the application)
// or: data-id (data element instance id) and data-table (= controller_name),
// this feature can only be used on one level of collapsibles within the
// same document.

import isTesting from "../utils/isTesting";

window.initialize_collapsible_remotes = function initialize_collapsible_remotes(){
  var elem = document.querySelectorAll('.collapsible.collapsible-remote');

  const testing = isTesting()
  const defaultOptions = {
    inDuration: testing ? 0 : 300,
    outDuration: testing ? 0 : 300
  }

  var initializeWidgets = function(scope) {
    // Note: ideally we would run all initialization code from
    // app/assets/javascripts/slplaner_initializers.js
    // on the newly loaded content

    M.Collapsible.init(
      scope.querySelectorAll('.collapsible'), defaultOptions
    );

    M.Collapsible.init(
      scope.querySelectorAll('.collapsible.secondary-collapsible'), defaultOptions
    );

    M.Collapsible.init(
      scope.querySelectorAll('.collapsible.secondary-collapsible.expandable'),
      {accordion: false, ...defaultOptions}
    );
  };

  var buildClassnameFromElement = function(element) {
    if (element.getAttribute("data-url") === null) {
      var table = element.getAttribute("data-table") || '';
      var id = element.getAttribute("data-id") || '';
      return table + id.toString() + "-slplaner-append-to";
    }

    return '';
  };

  var instance = M.Collapsible.init(elem,
    { onOpenStart: function(e){
        var url = e.getAttribute("data-url");
        var collapsible_body = e.querySelector('.collapsible-body.collapsible-remote');

        if (url) {
          var path = url;
          var dataType = "html";
        } else {
          // get id of collection element clicked on
          var table = e.getAttribute("data-table");
          var id = e.getAttribute("data-id");
          var querystring = e.getAttribute("data-querystring") || '';
          var classname = buildClassnameFromElement(e);
          collapsible_body.classList.add(classname);
          var path = "/" + table + "/" + id + querystring
          var dataType = "script";
        }

        // get data from show action
        $.ajax({
          type: "GET",
          url: path,
          dataType: dataType,
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          },
          xhrFields: {
            withCredentials: true
          }
        }).done(function(response) {
          if (this.dataType == "html") {
            $(collapsible_body).html(response)
            initializeWidgets(collapsible_body)
          }
        }).fail(function(jqXHR) {
          if (this.dataType == "html") {
            M.toast({html: jqXHR.responseText});
          }   
        });
      },
        
      onCloseEnd: function(e){
        var classname = buildClassnameFromElement(e);
        var collapsible_body = e.querySelector('.collapsible-body.collapsible-remote');

        // remove class
        if (classname.length > 0) {
          collapsible_body.classList.remove(classname);
        }

        // remove child elements
        while (collapsible_body.hasChildNodes()) {
          collapsible_body.removeChild(collapsible_body.firstChild);
        }     
      }, ...defaultOptions
    }
  );
}
