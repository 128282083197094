import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  show() {
    $('#select-von-bis-fields').removeClass('hide');
  }

  hide() {
    $('#select-von-bis-fields').addClass('hide');
  }
}
