import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // toggles visibility of dom elements dependent on selected radio button.
  // parameters: select_field_class, toggle_on_class, toggle_off_class, option_text.
  toggleTarget(event) {
    var selected = $('.' + event.params.selectFieldClass + ' input:checked');
    var target_on = $('.' + event.params.toggleOnClass);
    var target_off = $('.' + event.params.toggleOffClass);
    var text = event.params.optionText;

    if (selected && text) {
      var toggle_on = selected.siblings('span:first').text() == text;

      if (target_on) {
        toggle_on ? target_on.removeClass('hide') : target_on.addClass('hide');
      }

      if (target_off) {
        toggle_on ? target_off.addClass('hide') : target_off.removeClass('hide');
      }
    }
  }
}
