import {Controller} from "@hotwired/stimulus"
// import {useIntersection} from 'stimulus-use'
import appsignal from "../all/appsignal";
import isTesting from "../utils/isTesting";

export default class extends Controller {
  static values = {
    src: String
  }

  connect() {
    // useIntersection(this)

    this.element.addEventListener("turbo:frame-missing", async (event) => {
      event.preventDefault()
      event.stopPropagation()

      const testing = isTesting()

      M.toast({
        html: "Die Information konnte nicht abgerufen werden. Bitte probieren Sie es nochmal, oder kontaktieren Sie den Seabird-Kundensupport info@seabird.ch (Code 716).",
        inDuration: testing === null ? 350 : 0,
        outDuration: testing === null ? 375 : 0,
      })

      const error = new Error(`Unable to load data for Turbo frame, response details: ${await event.detail.response.text()}`)
      console.error(error)
      appsignal.getInstance().sendError(error, (span) => {
        span.setAction("turbo-frame-controller");
      })
    })
  }

  // appear(_entry, _observer) {
  //   if (!this.element.hasAttribute("src")) {
  //     if (this.hasSrcValue) {
  //       this.element.setAttribute("src", this.srcValue)
  //     } else {
  //       throw new Error("Missing source value to reload Turbo Frame!")
  //     }
  //   }

  //   if (this.element.hasAttribute("complete")) {
  //     this.element.reload()
  //   }
  // }

  // disappear(_entry, _observer) {
  //   this.element.innerHTML = ""
  // }
}
