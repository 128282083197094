import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.boundToggleClass = this.toggleClass.bind(this);
  } 

  connect() {
    this.element.addEventListener('click', this.boundToggleClass);
  }

  disconnect() {
    this.element.removeEventListener('click', this.boundToggleClass);
  }
    
  // Toggles a class on the closest element to the clicked element that
  // matches the selector defined in 
  // event.current_target.dataset.toggle_class_on_click_elements_selector.
  // If the clicked element matches the exclude selector, 
  // the class is not toggled.
  // The class to be toggled can be defined on either the element itself or 
  // the current_target. The definition on the element itself takes precedence.
  toggleClass(event) { 
    let ct = event.currentTarget;

    let toggle_elt = event.target.closest(ct.dataset.toggleClassOnClickElementsSelector);

    if (!toggle_elt) { return; }

    let toggle_class =
      toggle_elt.dataset.toggleClassOnClickClassName ||
      ct.dataset.toggleClassOnClickClassName;
    
    if (!toggle_class) { return; }
    
    let exclude_selector = ct.dataset.toggleClassOnClickExcludeSelector;

    if (!exclude_selector || !event.target.closest(exclude_selector)) {
      toggle_elt.classList.toggle(toggle_class);
    }
  }

}
