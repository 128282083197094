// swap sl and sv bemerkungen in schulverwaltungs#index and #index_anstellungen
window.toggle_sl_sv_bemerkungen = function toggle_sl_sv_bemerkungen(table, session_var, default_state_index){
  var sl_state;
  var sv_state;
  var states = [
    {sl: 1, sv: 0},
    {sl: 0, sv: 1},
    {sl: 1, sv: 1},
    {sl: 0, sv: 0}
  ]

  var sl_col_th = $('[data-col-name="sl_bemerkungen"]')[0];
  var sv_col_th = $('[data-col-name="sv_bemerkungen"]')[0];

  if (sl_col_th && sv_col_th &&
      sl_col_th.cellIndex && sv_col_th.cellIndex){
    sl_state = $(sl_col_th).hasClass("hide") ? 0 : 1 ;
    sv_state = $(sv_col_th).hasClass("hide") ? 0 : 1 ;

    var aggregate_state_index = states_indexOf(states,
      {sl: sl_state, sv: sv_state});

    var new_aggregate_state = states[next_state(states, aggregate_state_index,
      default_state_index)];

    // set new states
    sl_state = new_aggregate_state.sl;
    sv_state = new_aggregate_state.sv;

    var sl_cells = $('.sl-bemerkungen-cell');
    var sv_cells = $('.sv-bemerkungen-cell');

    sl_state == 1 ? $(sl_cells).removeClass('hide') : $(sl_cells).addClass("hide");
    sv_state == 1 ? $(sv_cells).removeClass('hide') : $(sv_cells).addClass("hide");

    // toggle_column(table, sl_col_th.cellIndex + 1, sl_state);
    // toggle_column(table, sv_col_th.cellIndex + 1, sv_state);

  } else {
    sl_state = states[default_state_index].sl;
    sv_state = states[default_state_index].sv;
    alert("Die Bemerkungen konnten nicht umgeschaltet werden. Bitte versuchen Sie es" +
      " noch einmal, oder kontaktieren Sie den Seabird-Kundensupport," +
      " info@seabird.ch. (Code 73)");
  }

  var path = "/slplaner_session_vars/remote_set_slplaner_session_var";
  var session_data = [
    { session_var_name: session_var + "_sl_state", value: sl_state },
    { session_var_name: session_var + "_sv_state", value: sv_state } ]
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: { session_data: session_data }
  });
  return false;
}

// toggle regular and pv bemerkungen in people#list
window.toggle_people_list_bemerkungen = function toggle_people_list_bemerkungen(table, session_var, default_state_index){
  var bemerkungen_state;
  var bemerkungen_col_th = $('[data-col-name="bemerkungen"]')[0];

  if (bemerkungen_col_th && bemerkungen_col_th.cellIndex){

    bemerkungen_state = $(bemerkungen_col_th).hasClass("hide") ? 0 : 1 ; // current state
    bemerkungen_state = (bemerkungen_state + 1) % 2; // new state
    toggle_column(table, bemerkungen_col_th.cellIndex + 1, bemerkungen_state);

  } else {
    bemerkungen_state = 1
    alert("Die Bemerkungen konnten nicht umgeschaltet werden. Bitte versuchen Sie es" +
      " noch einmal, oder kontaktieren Sie den Seabird-Kundensupport," +
      " info@seabird.ch. (Code 73a)");
  }

  var path = "/slplaner_session_vars/remote_set_slplaner_session_var";
  var session_data = [{ session_var_name: session_var + "_state", value: bemerkungen_state }]
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: { session_data: session_data },
  });
  return false;
}

window.toggle_sv_details = function toggle_sv_details(target_element){
  var button_element = $(target_element).closest('a');
  var button_id = $(button_element).attr('id');
  var new_state;
  var inactive_cols_button =
    $('#toggle-details-inactive-columns-on-button, #toggle-details-inactive-columns-off-button');

  if (button_id == "toggle-details-off-button"){
    $('.sv-detail-cell').addClass('hide');
    $(button_element).attr('id', "toggle-details-on-button");
    $(button_element).find('i').html("visibility");
    $(button_element).attr("data-tooltip", "Details einblenden");

    $(inactive_cols_button).attr('id', "toggle-details-inactive-columns-on-button");
    $(inactive_cols_button).find('i').html("skip_next");
    $(inactive_cols_button).attr("data-tooltip", "Alle Spalten einblenden");
    $(inactive_cols_button).addClass('hide');
    new_state = 0;
  } else {
    $('.sv-detail-cell').not('.hide-inactive').removeClass('hide');
    $(button_element).attr('id', "toggle-details-off-button");
    $(button_element).find('i').html("visibility_off");
    $(button_element).attr("data-tooltip", "Details ausblenden");
    $(inactive_cols_button).removeClass("hide");
    new_state = 1;
  }

  M.Tooltip.init($(button_element));

  var path = "/slplaner_session_vars/remote_set_slplaner_session_var";
  var session_data = [{session_var_name: "sv_table_details_state", value: new_state}]
  if (button_id == "toggle-details-off-button"){
    session_data.push({session_var_name: "sv_table_details_inactive_columns_state", value: 0});
  }
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: { session_data: session_data },
  });
  return false;
}

window.toggle_sv_details_inactive_columns = function toggle_sv_details_inactive_columns(target_element){
  var button_element = $(target_element).closest('a');
  var button_id = $(button_element).attr('id');
  var new_state;

  if (button_id == "toggle-details-inactive-columns-off-button"){
    $('.sv-detail-cell.hide-inactive').addClass('hide');
    $(button_element).attr('id', "toggle-details-inactive-columns-on-button");
    $(button_element).find('i').html("skip_next");
    $(button_element).attr("data-tooltip", "Alle Spalten einblenden");
    new_state = 0;
  } else {
    $('.sv-detail-cell.hide-inactive').removeClass('hide');
    $(button_element).attr('id', "toggle-details-inactive-columns-off-button");
    $(button_element).find('i').html("skip_previous");
    $(button_element).attr("data-tooltip", "Nicht verwendete Spalten ausblenden");
    new_state = 1;
  }

  M.Tooltip.init($(button_element));

  var path = "/slplaner_session_vars/remote_set_slplaner_session_var";
  var session_data = [{session_var_name: "sv_table_details_inactive_columns_state", value: new_state}]
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: { session_data: session_data },
  });
  return false;
}

///////////////////////////////////////////////////////////////////////////////
//// helper functions
///////////////////////////////////////////////////////////////////////////////

// get cells in column with index col_index of table "table" and toggle
// show/hide. state is state to toggle to, 1 = show, 0 = hide.
window.toggle_column = function toggle_column(table, col_index, state){
  var cells = $(table).find('th:nth-child(' + col_index + '), td:nth-child(' + col_index + ')');
  state == 1? $(cells).removeClass("hide") : $(cells).addClass("hide");
}

// get index of search_element in array. search_element is a javascript object with
// two key/value pairs, array is an array of such objects, each of which has the
// same two keys as search_element. if not found, return -1.
window.states_indexOf = function states_indexOf(array, search_element){
  var key1, key2;
  for(var i = 0; i < array.length; i++){
    key1 = Object.keys(search_element)[0];
    key2 = Object.keys(search_element)[1];
    if (array[i][key1] == search_element[key1] && array[i][key2] == search_element[key2]){
      return i;
    }
  }
  return -1;
}

// get next aggregate state index, depending on default_state_index. on sv views, this will
// ensure that toggling will always initially be sl -> sv or the other way
// around, before both or none of sl and sv properties are shown.
// assumes that array has elements of the form {sl: integer, sv; integer} and
// that {sl: 0, sv: 1} and {sl: 1, sv: 0} are in the first two positions.
window.next_state = function next_state(array, state_index, default_state_index){
  if (state_index < 0) return default_state_index;

  var length = array.length;
  var toggle_order = [];

  for (var i=0; i < length; i++){
    toggle_order[i] = i;
  }

  // if default state is 1, change toggle order of first two elements
  if (default_state_index == 1 && length >= 2){
    toggle_order[0] = 1;
    toggle_order[1] = 0;
  }

  return toggle_order[(toggle_order.indexOf(state_index % length) + 1) % length];
}
