import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.stopPropagation);
    this.element.addEventListener('touchend', this.stopPropagation);
  }

  disconnect() {
    this.element.removeEventListener('click', this.stopPropagation);
    this.element.removeEventListener('touchend', this.stopPropagation);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

}
