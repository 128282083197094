// # Place all the behaviors and hooks related to the matching controller here.
// # All this logic will automatically be available in application.js.
// # You can use CoffeeScript in this file: http://coffeescript.org/

///////////////////////////////////////////////////////////////////////////////
// match one resource to one person in two_col_layout
///////////////////////////////////////////////////////////////////////////////

window.match_two_col_clicks = function match_two_col_clicks(e){

  // if same start element is clicked on a second time before end element
  // is chosen, reset and exit
  if (e.target == window.start_elt){
    match_reset(e)
    return;
  }

  // in case simple match and multi-match both occur on page, reset before
  // switching from one to the other.
  if ($(window.start_elt).hasClass('multi-match')){
    match_reset();
  }

  var target = e.target; // dom-element clicked on
  var classlist = target.classList; // this element's classes

  var match_start_side_new; // match side of current click
  var ends; // end elements
  var end; // id of selected end element
  var end_elt; // selected end element

  // which side was clicked on
  if (classlist.contains("match-left")){
    match_start_side_new = "left";
  } else if (classlist.contains("match-right")){
    match_start_side_new = "right";
  } else {
    match_start_side_new = false;
  }

  // start element chosen? (if first chosen element, or if same side as before)
  if (!window.begin || (match_start_side_new && (match_start_side_new == window.match_start_side))){

    window.match_start_side = match_start_side_new; // set global var match_start_side
    if (window.match_start_side == "left"){
      ends = document.querySelectorAll(".match-link.match-right:not(.multi-match)");
    } else if (window.match_start_side == "right"){
      ends = document.querySelectorAll(".match-link.match-left:not(.multi-match)");
    }

    // if other start elt was previously chosen, restore previous element to
    // default style
    if (window.start_elt){
      toggle_match_link_appearance(window.start_elt, "inactive");
    }

    window.start_elt = target; // get new start point, set globel var start_elt
    // change appearance of start elt
    toggle_match_link_appearance(window.start_elt, "selected");

    // if end elements are present
    if (ends.length > 0){

      window.begin = target.getAttribute("data-id"); // get element id, set global var start
      window.table1 = target.getAttribute("data-table"); // set global var table1

      // change appearance of all possible end elts to draw attention
      [].forEach.call(ends, function(e){
        toggle_match_link_appearance(e, "active");
      })

      // if there are no end elements (on small screens), get associated
      // table and call to assign
      // var left_column = document.getElementById("slplaner-two-col-content-right");
      // if ($(left_column).css('display') == 'none'){
      //   table2 = target.getAttribute("data-assoc_table");
      //   end = null;
      //   call_to_assign(table1, table2, start, end);
      // }
    }

  // end element chosen? (there is already a start element, and new element
  // is on other side) -> if start is valid, call assignment method
  } else if (window.begin && (match_start_side_new && (match_start_side_new != window.match_start_side))){

    window.match_start_side = match_start_side_new; // set global var match_start_side
    ends = document.getElementsByClassName("match-link match_" + window.match_start_side);

    end_elt = target; // get end point
    end = target.getAttribute("data-id"); // get id
    window.table2 = target.getAttribute("data-table");
    // restore standard appearance of all end elements
    [].forEach.call(ends, function(e){
      toggle_match_link_appearance(e, "inactive");
    })
    // change appearance of chosen element
    toggle_match_link_appearance(end_elt, "selected");

    // call to assign
    call_to_assign(window.table1, window.table2, window.begin, end);
  }
}

///////////////////////////////////////////////////////////////////////////////
// match one-to-many, either way (one resource to many people or one person
// to many resources) in two_col_layout
///////////////////////////////////////////////////////////////////////////////

window.multi_match_two_col_clicks = function multi_match_two_col_clicks(e){

  // if same start element is clicked on a second time before end element
  // is chosen, reset and exit
  if (e.target == window.start_elt){
    match_reset();
    return;
  }

  // in case simple match and multi-match both occur on page, reset before
  // switching from one to the other.
  if (!$(window.start_elt).hasClass('multi-match')){
    match_reset();
  }

  var target = e.target; // dom-element clicked on
  var classlist = target.classList; // this element's classes
  var match_start_side_new; // match side of current click
  var match_end_side; // other match side, however this is defined at the point of use
  var ends; // end elements

  // which side was clicked on
  if (classlist.contains("match-left")){
    match_start_side_new = "left";
  } else if (classlist.contains("match-right")){
    match_start_side_new = "right";
  } else {
    match_start_side_new = false;
  }

  // start element chosen? (if first chosen element, or if same side as before)
  if (!window.begin || (match_start_side_new && (match_start_side_new == window.match_start_side))){

    // if other start elt was previously chosen, restore previously selected
    // elements to default style
    if (window.start_elt){
      match_reset();
    }

    window.match_start_side = match_start_side_new; // set global var match_start_side
    match_end_side = get_match_end_side(window.match_start_side);

    window.start_elt = target; // get new start point, set globel var start_elt
    window.begin = target.getAttribute("data-id"); // get element id, set global var start
    window.table1 = target.getAttribute("data-table"); // set global var table1
    // change appearance of start elt
    toggle_match_link_appearance(window.start_elt, "selected");

    // get end table
    window.associated_table = target.getAttribute("data-assoc_table");
    if (window.associated_table){
      window.table2 = window.associated_table;
    } else if (ends[0]) {
      window.table2 = ends[0].getAttribute("data-table");
    } else {
      window.table2 = null;
    }

    // get current membership data
    var controller = document.querySelector("meta[name = 'psj']").getAttribute('controller');
    var path = "/" + controller + "/get_match_data";

    window.match_table = match_table_lookup(window.table1, window.table2);

    $.ajax({
      type: "GET",
      url: path,
      dataType: "script",
      data: {id: window.begin, table1: window.table1, table2: window.table2, match_table: window.match_table,
        match_start_side: window.match_start_side, match_end_side: match_end_side}
    });
  }
}

window.grading_radio_checkboxes_click = function grading_radio_checkboxes_click(e){
  var ordinal_grade = $(e.target).attr('data-ordinal-grade');
  var siblings = $(e.target).siblings('.multi-match-checkbox.grading');
  var twin = $(siblings).filter('[data-ordinal-grade=' + ordinal_grade + ']');

  $(e.target).addClass('hide');
  $(siblings).filter('.checked').addClass('hide');
  $(siblings).filter('.neutral').removeClass('hide');
  $(twin).removeClass('hide');
}

///////////////////////////////////////////////////////////////////////////////
// helper functions
///////////////////////////////////////////////////////////////////////////////

// call match reset on click anywhere, if conditions are met
window.call_match_reset = function call_match_reset(e){
  // matching is in progress (and thus needs to be reset)
  if (window.match_start_side) {
    // match elements and descendants of .match.li elements
    var is_match_elt = e.target.classList.contains("match");
    var desc_of_match_li = e.target.closest('li') &&
      e.target.closest('li').classList.contains("match");
    
    var noteable_header_in_pf_match =
      $(window.start_elt).closest("i").attr("data-assoc_table") == "pf_noteables" &&
      e.target.closest('div.collapsible-header') && 
      e.target.closest('div.collapsible-header').classList.contains("pf-noteable-collapsible-header");

    // if not clicked on one of the above elements, call match reset
    if (!is_match_elt && !desc_of_match_li && !noteable_header_in_pf_match){
      match_reset();
    }
  }
}

// calls action new on match_table (create new match table element), with
// preset values from start and end elements
window.call_to_assign = function call_to_assign(table1, table2, start, end){
  window.match_table = match_table_lookup(table1, table2);
  var path = "/matches/new_match";
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script",
    data: {match_table: window.match_table, table1: table1, table2: table2,
      table1_id: start, table2_id: end}
  });
}

window.get_match_end_side = function get_match_end_side(side){
  if (side == "left"){
    return "right";
  } else if (side == "right"){
    return "left";
  } else {
    return null;
  }
}

// if clicked on anything but a match element, or if clicked on same start
// element twice, reset all match elements and variables
window.match_reset = function match_reset(){
  // if matching is in progress (thus needs to be reset)
  if (window.match_start_side) {
    $('.match-link').each(function(){
      toggle_match_link_appearance($(this)[0], "inactive");
      $(this).removeClass('hide');
      $(this).off();
      $(this).on('click', function(e){
        e.stopPropagation();
        if ($(this).hasClass('multi-match')){
          multi_match_two_col_clicks(e);
        } else {
          match_two_col_clicks(e);
        }
      });
    });

    // hide additional match elements (non-link elements)
    $('.match:not(.match-link)').addClass("hide");
    $('.match.match-member').addClass('hide');
    $('.match.match-member').children().addClass('hide');
    $('.match.match-button-li.gleich-wie').removeClass('orange-text text-accent4');
    $('.match.match-button-li.gleich-wie').find('i.material-icons')
      .removeClass('orange-text text-accent4');

    // restore "navbar-items" data attribute
    var elt;
    var navbar_items;
    var navbar_items_difference;
    $('.slplaner-two-col-navbar').each(function() {
      elt = $(this).find('.slplaner-col-navbar').first();
      navbar_items = elt.attr('data-navbar-items');
      navbar_items_difference = elt.attr('data-navbar-difference');
      if (navbar_items && navbar_items_difference){
        elt.attr('data-navbar-items', (parseInt(navbar_items) - parseInt(navbar_items_difference)));
        elt.attr('data-navbar-difference', null);
      }
    });

    // restore dropdown classes on navbar elts not involved in matching
    $( '.people-list-add-button-li, ' +
       '.people-filter-button-li, ' +
       '.people-list-freigabe-button-li, .people-list-import-one-person-button-li')
      .removeClass('slplaner-go-to-dropdown-li no-propagation');
    $( '.people-list-import-vj-button-li, .people-list-import-one-person-button-li, ' +
       '.people-filter-button-li')
      .removeClass('hide-in-dropdown');

    window.match_start_side = null; // side of two_col_layout clicked on
    window.begin = null; // database record id of start record
    window.start_elt = null; // start-element in dom
    window.table1 = null; // table on start side
    window.table2 = null; // table on end side
    window.match_table = null; // table combining start and end elements
  }
}

// look up table connecting table1 and table2
window.match_table_lookup = function match_table_lookup(table1, table2){
  // the value in the inner hash is the table which belongs_to both the first
  // and the second key, e.g. amt_people belongs_to amts and to people

  var dict = {
    amts: {people: "amt_people"},
    anstellung_weiteres: {people: "anstellung_weitere_people"},
    betreuungs: {people: "betreuung_people"},
    klassenassistenzs: {people: "klassenassistenz_people"},
    klasses: {people: "klps"},
    lektionen_solls: {people: "lektionen_people"},
    lektionen_weiteres: {people: "lektionen_weitere_people"},
    pf_noteables: {pfile_event_people: "pf_notes"},
    pfile_events: {people: "pfile_event_people"},
    sitzungs: {people: "sitzung_people"},
    sonderpaeds: {people: "sopaed_people"}
  }

  var table_array = [table1, table2];
  var i = 0;
  var match_table = null;
  while (i < 2 && match_table == null){
    if (table_array[i] in dict){
      match_table = dict[table_array[i]][table_array[(i+1)%2]];
    }
    i += 1;
  }
  return match_table;
}

// toggle appearance of match links
window.toggle_match_link_appearance = function toggle_match_link_appearance(e, appearance){
  if (appearance == "selected"){
    e.classList.remove("grey-text", "text-darken-4");
    e.classList.add("lime", "accent-2", "orange-text", "text-accent-4");
  } else if (appearance == "active"){
    e.classList.remove("lime", "accent-2", "grey-text", "text-darken-4");
    e.classList.add("orange-text", "text-accent-4");
  } else if (appearance == "inactive"){
    e.classList.remove("lime", "accent-2", "orange-text", "text-accent-4");
    e.classList.add("grey-text", "text-darken-4");
  }
}
