import { phone_modal } from "../utils/landing_page"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', function () {
      phone_modal(
        this.dataset.phoneModalName,
        this.dataset.phoneModalNr,
        this.dataset.phoneModalName2,
        this.dataset.phoneModalNr2
      );
    });
  }
}