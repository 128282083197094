import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.disableAfterClick.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('click', this.disableAfterClick.bind(this));
  }

  disableAfterClick(event) {
    const link = event.currentTarget;
    link.style.pointerEvents = 'none';
    link.style.cursor = 'default';
  }

}
