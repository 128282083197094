import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const collapsibleHeader = this.element.querySelector('.collapsible-header');

    if (collapsibleHeader) {
      this.scrollIntoView(collapsibleHeader, '.slplaner-col-content-column', -135);
    }
  }

  scrollIntoView(elt, scroll_parent_selector, offset, duration = 10) {
    const scroll_parent = $(elt).closest(scroll_parent_selector);
    var elt_offset = $(elt).position().top - scroll_parent.position().top;
    var current_scroll = scroll_parent.scrollTop();
  
    scroll_parent.animate({scrollTop: elt_offset + offset + current_scroll}, duration);
  }

}