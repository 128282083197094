import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["schuleSelect", "roleSelect", "localUserSelect", "localUserSelectFrame"]

  connect() {
    this.localUserSelectFrameTarget.addEventListener("turbo:frame-load", () => {
      // TODO could be replaced when select are initialised with Stimulus
      M.FormSelect.init(this.localUserSelectFrameTarget.querySelector("select"), {
        dropdownOptions: {
          container: document.body
        }
      })
    })
  }

  updateLocalUser() {
    if (this.schuleSelectTarget.value === "") {
      this.hideLocalUserSelect()
      return
    }

    if (this.roleSelectTarget.value === "schule_mitarbeiter") {
      this.localUserSelectFrameTarget.setAttribute("src", `/user_schule_people/fetch_local_users?schule_id=${this.schuleSelectTarget.value}`)
      this.localUserSelectFrameTarget.classList.remove("hide")
    } else {
      this.hideLocalUserSelect()
    }
  }

  clearLocalUserSelect() {
    // could be that the select does not exist because it is not necessary
    // basically choosing any other role than schule_mitarbeiter"
    if (this.hasLocalUserSelectTarget) {
      this.localUserSelectTarget.value = "";
    }
  }

  hideLocalUserSelect() {
    this.localUserSelectFrameTarget.classList.add("hide")
    this.clearLocalUserSelect()
  }
}
