import { getCookie } from "../utils/cookies";
import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    let dse_cookie = getCookie('slplaner_dse');

    if (!dse_cookie || !dse_cookie.startsWith('consented')) {
      Rails.ajax({
        url: "/datenschutzerklaerungs/get_datenschutzerklaerung_consent", 
        type: "GET"
      });
    }
  }
}