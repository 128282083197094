/////////////////////////////////////////////////////////////////////////////
// contract expiration notices and denial of service because of missing
// contract
/////////////////////////////////////////////////////////////////////////////

window.show_contract_expiration_notices = function show_contract_expiration_notices(controller, action) {
  // contract expiration notices on schules/index
  if (controller == "schules" && action == "index"){
    $.ajax({
      type: "GET",
      url: '/schule_products/contract_expiration_notice',
      dataType: "script"
    });
  }
}
