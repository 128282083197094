// generate random nr to use e.g. as identifier where full uuid is not needed
// and shorter string preferred.
// reference: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid,
// broofa's answer
window.short_random_id = function short_random_id() {
  return 'xxxxyxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// expands all elements of a materialize collapsible.
// arguments: collapsible must be a dom ul element
// which has been initialized as a materialize collapsible.
window.expand_all = function expand_all(collapsible) {
  var instance = M.Collapsible.getInstance(collapsible);
  const children = $(collapsible).children.length;
  for (var i = 0; i < children; i++) {
    instance.open(i);
  }
}

// collapses all elements of a materialize collapsible.
// arguments: collapsible must be a dom ul element
// which has been initialized as a materialize collapsible.
window.collapse_all = function collapse_all(collapsible) {
  var instance = M.Collapsible.getInstance(collapsible);
  const children = $(collapsible).children.length;
  for (var i = 0; i < children; i++) {
    instance.close(i);
  }
}
