import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // toggles visibility of dom element dependent on
  // select option. parameters: id, select_field_id, option_text.
  toggleTarget(event) {
    var selected = $('#' + event.params.selectFieldId + ' option:selected');
    var target = $('#' + event.params.id);
    var text = event.params.optionText;

    if (selected && target && text) {
      selected.text() == text ?
        target.removeClass('hide') :
        target.addClass('hide');
    }
  }
}
