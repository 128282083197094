window.activate_schule_submit_button_placeholder_listeners = function activate_schule_submit_button_placeholder_listeners() {
  var elt = $('#schules-form-submit-button-placeholder');
  elt.on('click', function(){
    if (elt.hasClass('with-message')){
      var message =
        "Falls Sie den Anschluss an eine Schulgemeinde oder " +
        "Tagesstrukturen-Zentrale geändert haben: SIND SIE SICHER?"
      if(confirm(message)){ click_on_schule_submit(); }
    } else {
      click_on_schule_submit();
    }
  });
}

window.click_on_schule_submit = function click_on_schule_submit(){
  $('.progress').removeClass("hide");
  $("#schules-form-submit-button").click();
  $("#schules-form-submit-button").attr("disabled","disabled");
}
