// abc scrolling by keydown on letter keys

window.initialize_scroll_on_keydown = function initialize_scroll_on_keydown(){
  if ($('.scroll-contents-on-keydown').length > 0) {
    document.addEventListener("keydown", scroll_to_abc);
  }
};

window.deactivate_scroll_on_keydown = function deactivate_scroll_on_keydown(){
  if ($('.scroll-contents-on-keydown').length > 0) {
    document.removeEventListener("keydown", scroll_to_abc);
  }
};
