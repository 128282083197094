window.onclick_toggle_pfile_event_bearbeitungsstatus = function onclick_toggle_pfile_event_bearbeitungsstatus(e){
  var elt = $(e.target).closest('a');
  var id = $(elt).attr('data-record-id');
  var bearbeitungsstatus = $(elt).attr('data-new-b-status');

  $(elt).addClass('disabled');
  toggle_pfile_event_bearbeitungsstatus(id, bearbeitungsstatus);
}

window.toggle_pfile_event_bearbeitungsstatus = function toggle_pfile_event_bearbeitungsstatus(id, bearbeitungsstatus){
  var path = "pfile_event_people/" + id + "/toggle_bearbeitungs_status";

  $.ajax({
    type: "PATCH",
    url: path,
    dataType: "script",
    data: {
      pfile_event_person: {bearbeitungs_status: bearbeitungsstatus},
      authenticity_token: $('[name="csrf-token"]')[0].content
    }
  });
  return false;
}

window.initialize_pep_bearbeitungsstatus_toggler = function initialize_pep_bearbeitungsstatus_toggler(element){
  if (element){
    $(element).removeClass("disabled")
    $(element).click(
      function(e){
        var confirm_text = $(e.target).closest('a').attr('data-c');
        if(confirm(confirm_text)){
           onclick_toggle_pfile_event_bearbeitungsstatus(e);
        }
      }
    );
  }
}
