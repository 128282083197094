// subscribes to this channel when function is called, e.g. when download
// button is clicked.
// starts zip file creation in background job on subscribed.
// should receive link to download zip file via data in received.
// on received, calls url to download the zip file, handles feedback to user
// on front end, reactivates buttons, and unsubscribes from channel.

const actionCable = require("@rails/actioncable")

export function subscribe_to_download_all_pv_notifications_channel(
  download_id, url_to_create_pv_zipfile, upids
) {

  actionCable.createConsumer().subscriptions.create(
    {
      channel: "DownloadAllPvNotificationsChannel",
      download_id: download_id
    },

    {
      connected() {

        // if this download request was already handled, do nothing. otherwise
        // call create_pv_zipfile.
        if (download_id && !(Slplaner.download_ids && Slplaner.download_ids.includes(download_id))) {

          Slplaner.download_ids ?
            Slplaner.download_ids.push(download_id) :
            Slplaner.download_ids = [download_id];

          $.ajax({
            type: "GET",
            url: url_to_create_pv_zipfile,
            dataType: "script",
            data: {
              download_id: download_id,
              upids: upids
            }
          });

          download_id = null;
        }
      },

      received(data) {

        var toast = $('#' + data.download_id).closest('.download-all-pv-toast');
        var toast_message_elt = $(toast).find('.download-all-pv-msg');
        var toast_progress_elt = $(toast).find('.download-all-pv-progress');

        if (data.do == 'download_zip_file') {

          $(toast_message_elt).html("Ihre Daten sind zum Download bereit.");
          $(toast_progress_elt).addClass('hide');
          $(toast_progress_elt).removeClass('progress').empty();
          $(toast_progress_elt).html(
            $('<a>', {
              class: "zip-download-link waves-effect waves-light btn seabird-color",
              text: "Herunterladen",
              href: data.zip_download_path,
              css: {"font-weight": "bold"},
              download: ""
            })
          );
          $(toast_progress_elt).removeClass('hide');

        } else if (data.do == 'finish_after_success') {

          this.reactivate_buttons();
          this.unsubscribe();

        } else if (data.do == 'error_message_and_cleanup') {

          var message = "Die Daten konnten nicht heruntergeladen werden. Bitte versuchen \
            Sie es nochmal, oder kontaktieren Sie den Seabird-Kundensupport, \
            info@seabird.ch. (Code 801c" + data.error_code + ")"

          $(toast_progress_elt).addClass('hide');
          $(toast).removeClass('cancellation-notice');

          M.toast({
            displayLength: Slplaner.download_all_pv_toast_duration,
            html: message
          });

          this.reactivate_buttons();
          this.unsubscribe();
        }
      },

      rejected() {

        var message = "Die Daten konnten nicht heruntergeladen werden. Bitte versuchen \
        Sie es nochmal, oder kontaktieren Sie den Seabird-Kundensupport, \
        info@seabird.ch. (Code 801f)"

        $('.download-all-pv-progress').addClass('hide');

        M.toast({
          displayLength: Slplaner.download_all_pv_toast_duration,
          html: message
        });

        this.reactivate_buttons();
      },

      disconnected() {

        $('.download-all-pv-progress').addClass('hide');
        this.reactivate_buttons();

      },

      reactivate_buttons() {
        $('.download-all-pv-button').removeClass('inactive');
      },
    }
  );
}
