import Appsignal from "@appsignal/javascript";
import {plugin as windowEventsPlugin} from "@appsignal/plugin-window-events";
import {plugin as pathDecoratorPlugin} from "@appsignal/plugin-path-decorator";

const appsignal = () => {
  let instance;

  function createInstance() {
    const appsignalFrontendApiKeyHeader = document.head.querySelector('[name~=appsignal_frontend_api_key][content]')
    const appRevisionHeader = document.head.querySelector('[name~=app_revision][content]')

    const appsignal = new Appsignal({
      key: appsignalFrontendApiKeyHeader === null ? '' : appsignalFrontendApiKeyHeader.content,
      revision: appRevisionHeader === null ? '' : appRevisionHeader.content
    })

    appsignal.use(windowEventsPlugin())
    appsignal.use(pathDecoratorPlugin())

    return appsignal
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance()
      }

      return instance
    }
  }
}

export default appsignal()
