window.choose_signin_or_signup_form = function choose_signin_or_signup_form(form_type) {
  var form_fields;

  if(form_type == "signup"){
    $('#username-password-signin-form').addClass('hide');
    $('#signup-form').removeClass('hide');
  } else {
    $('#signup-form').addClass('hide');
    $('#username-password-signin-form').removeClass('hide');
    $("[data-focused='true']").focus();
  }
}
