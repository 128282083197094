import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.boundHandleContextMenu = this.handleContextMenu.bind(this);
  } 

  connect() {
    this.element.addEventListener('contextmenu', this.boundHandleContextMenu);
  }

  disconnect() {
    this.element.removeEventListener('contextmenu', this.boundHandleContextMenu);
  }
    
  handleContextMenu(event) {
    event.preventDefault();
    return false;
  }

}
