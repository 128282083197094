window.interactive_sv_initializers = function interactive_sv_initializers(){
  initialize_interactive_sv_filter_dropdown();
  initialize_interactive_sv_dd_trigger();
  initialize_interactive_sv_markierung_loeschen();
  initialize_sv_remove_all_freigabe_notifications();
};

window.sv_initializers = function sv_initializers(action){
  var people_table = document.getElementById("people-table");
  var colorable_rows = $(people_table).find('tbody tr').not('.no-colors');

  // show sv_person in modal
  $('.sv-person-link').on('click', function(e){
    show_sv_person_in_modal(
      $(e.target).closest('tr').attr('data-undeletable-person-id')
    );
  });

  // listener on änderungsanzeigen
  $('#aenderungsanzeigen-button').on('click', function(e){
    show_freigabes_in_modal();
  });

  // listener on änderungsanzeigen_notify
  $('#aenderungsanzeigen-notify-button').on('click', function(e){
    elt = $(e.target).closest('li');
    $(elt).addClass('hide');
    $('#aenderungsanzeigen-button').removeClass('hide');
    show_freigabes_in_modal();
  });

  // listener on bemerkungen swap button
  $('#bemerkungen-swap-button').on('click', function(){
    toggle_sl_sv_bemerkungen(people_table, "sv_table_bemerkungen", 1);
  });

  // listener on sv_details toggle buttons
  $('#toggle-details-on-button, #toggle-details-off-button')
    .on('click', function(e){ toggle_sv_details(e.target); });

  $('#toggle-details-inactive-columns-on-button, #toggle-details-inactive-columns-off-button')
    .on('click', function(e){ toggle_sv_details_inactive_columns(e.target); });

  // remove freigabe notification on sv_person
  $('.person-icon-li').css('cursor', 'pointer').on('click', function(e){
    e.stopPropagation();
    var li_elt = $(e.target).closest('.person-icon-li');
    if($(li_elt).hasClass('active')){
      var alert_elt = $(li_elt).children('.person-alert');
      if(confirm("Sind Sie sicher: Hinweis entfernen?")){
        dismiss_freigabe_notification(li_elt, alert_elt, action);
      }
    }
  });

  initialize_sv_remove_all_freigabe_notifications();

  $('#sv-index-filter-button').on('click', function(e){
    sv_filter_table_by_cols(e.target, people_table); }
  );

  // listener on markierung löschen button, deletes all colors
  $('#markierung-loeschen-button').on('click', function(){
    var colored_cells =
      $(colorable_rows).find('td.cc, th.cc').not('tr.hide td').not('tr.hide th');

    if (colored_cells.length > 0){
      if ( confirm("Sind Sie sicher: Alle Farben entfernen? DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.") ){
        sv_markierung_loeschen(colored_cells, action);
      }
    } else {
      M.toast({html: 'Es sind keine Farben vorhanden.'});
      return false;
    }
  });

  // pointer cursor on cells, and click listener for
  // color coding
  $(colorable_rows).find('th, td').not('.no-color')
    .css('cursor', 'pointer')
    .on('click', function(e){set_sv_color(e, people_table, action);});

  if (action == "index"){
    $('.clickable-anstellungen-nr')
      .css('cursor', 'pointer')
      .on('click', function(e){
        e.stopPropagation();
        toggle_person_anstellungen_in_table(e.target);
      });

    initialize_interactive_sv_filter_dropdown();
    initialize_interactive_sv_dd_trigger();
  }
};

window.initialize_interactive_sv_filter_dropdown =
    function initialize_interactive_sv_filter_dropdown(){

  var elems = document.querySelectorAll('.sv-filter-button');
  M.Dropdown.init(elems, {
    constrainWidth: false,
    coverTrigger: false,
    closeOnClick: false });
};

window.initialize_interactive_sv_dd_trigger =
    function initialize_interactive_sv_dd_trigger(){

  $('.dd-trigger').click(function(e){
    var targ_id = e.target.getAttribute('data-dd-target');
    var trigg_elt = e.target.closest('.sv-filter-button-li').querySelector('a.sv-filter-button');

    $('.sv-filter-select').not('#' + targ_id).addClass("hide");
    $('.dd-trigger').not(e.target).removeClass("sv-filter-select-background");
    $(e.target).toggleClass("sv-filter-select-background");
    $('#' + targ_id).toggleClass("hide");

    var inst = M.Dropdown.getInstance(trigg_elt);
    inst.close();
    inst.open();
  });
};

window.initialize_interactive_sv_markierung_loeschen =
    function initialize_interactive_sv_markierung_loeschen(){

  $('.markierung-loeschen-button').on('click', function(){
    window.colored_cells = $('div.cc').not('.svp-header-li.hide div');

    if (window.colored_cells.length > 0){
      if ( confirm("Sind Sie sicher: Alle Farben entfernen? DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.") ){
        interactive_sv_markierung_loeschen(window.colored_cells);
      }
    } else {
      M.toast({html: 'Es sind keine Farben vorhanden.'});
      return false;
    }
  });
};

window.initialize_sv_remove_all_freigabe_notifications =
    function initialize_interactive_sv_remove_all_freigabe_notifications(){

  $('.remove-all-freigabe-notifications-button').on('click', function(e){
    var alert_elts = $('.person-alert')
      .not('.svp-header-li.hide .person-alert')
      .not('tr.hide .person-alert')

    if (alert_elts.length > 0){
      if (confirm("Sind Sie sicher: Alle Freigabe-Anzeigen entfernen? DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.")){
        sv_remove_all_freigabe_notifications(alert_elts);
      } else {
        return false;
      }
    } else {
      M.toast({html: 'Es sind keine Freigabe-Anzeigen vorhanden.'});
      return false;
    }
  });
};
