window.initialize_slplaner_datepicker = function initialize_slplaner_datepicker(element, min_date = null, max_date = null) {

  var datepicker_settings = {
    autoClose: true,
    format: 'dd.mm.yyyy',
    setDefaultDate: true,
    firstDay: 1,
    minDate: new Date(min_date),
    maxDate: new Date(max_date),
    showDaysInNextAndPreviousMonths: true,
    showClearBtn: false,
    i18n: {
      cancel: "Abbrechen",
      done: "OK",
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      monthsShort: [
        'Jan',
        'Feb',
        'Mrz',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Okt',
        'Nov',
        'Dez'
      ],
      weekdays: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag'
      ],
      weekdaysShort: [
        'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'
      ],
      weekdaysAbbrev: ['S','M','D','M','D','F','S']
    }
  };

  $(element).find('.datepicker').each(function(){
    datepicker_settings.defaultDate = new Date($(this).data('date'));
    if (!min_date){datepicker_settings.minDate = new Date($(this).data('min-date'));}
    if (!max_date){datepicker_settings.maxDate = new Date($(this).data('max-date'));}
    M.Datepicker.init($(this), datepicker_settings);
  });
}
