import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.instance = M.Collapsible.init(this.element,
      {
        accordion: false
      }
    )
  }

  disconnect() {
    this.instance.destroy()
  }
}
