// show sl_svs in modal
window.show_freigabes_in_modal = function show_freigabes_in_modal(){
  var path = "../schulverwaltungs/show_freigabes_in_modal";
  $.ajax({
    type: "GET",
    url: path,
    dataType: "script"
  });
}

///////////////////////////////////////////////////////////////////////////////
// Personen freigeben für Anstellung,
// Freigabe zurückziehen, Anstellungsauftrag ändern, etc.
///////////////////////////////////////////////////////////////////////////////

// show freigabe elements on people
window.show_freigabe_elements = function show_freigabe_elements(){

  // if clicked on this button for the second time, exit freigabe edit mode
  if (window.freigabe_edit_on == true){
    freigabe_reset();
    return;
  }

  window.freigabe_edit_on = true;

  // hide match link elements during freigabe
  var match_links = $('.match-link-li');
  if (match_links){
    $(match_links).addClass('hide');
  }

  var freigabe_checkboxes = $('.freigabe');

  // add/remove hide on checkboxes depending on current freigabe status
  freigabe_checkboxes.each(function(){
    if ($(this).attr('current-freigabe') == "true"){
      $(this).children('.freigabe-current.checked').removeClass('hide');
      $(this).children('.freigabe-current.unchecked').addClass('hide');
    } else {
      $(this).children('.freigabe-current.checked').addClass('hide');
      $(this).children('.freigabe-current.unchecked').removeClass('hide');
    }
  })

  // show button set
  $('.freigabe-button-li').removeClass('hide');

  // change "navbar-items" data attribute
  var elt = $('#slplaner-two-col-navbar-right, #slplaner-one-col-navbar')
    .find('.slplaner-col-navbar').first();
  var navbar_items = elt.attr('data-navbar-items');
  if (navbar_items){
    elt.attr('data-navbar-items', (parseInt(navbar_items) + 4));
    elt.attr('data-navbar-difference', '4');
  }

  // change dropdown classes on navbar elts not involved in freigabe
  $( '.people-list-add-button-li, ' +
     '.people-filter-button-li, ' +
     '.people-list-freigabe-button-li, .people-list-import-one-person-button-li')
    .addClass('slplaner-go-to-dropdown-li no-propagation');
  $( '.people-list-import-vj-button-li, .people-list-import-one-person-button-li, ' +
     '.people-filter-button-li')
    .addClass('hide-in-dropdown');

  // show checkboxes
  freigabe_checkboxes.removeClass('hide');
  freigabe_checkboxes.children('.freigabe-new.neutral').removeClass('hide');
}

// alle personen für freigabe markieren (only includes
// filtered_in, i.e. visible people)
window.freigabe_alle = function freigabe_alle() {
  var freigabe_checkboxes = filtered_freigabe_elements();

  $(freigabe_checkboxes).attr('new-freigabe', "true");
  $(freigabe_checkboxes).children('.freigabe-new.neutral').addClass('hide');
  $(freigabe_checkboxes).children('.freigabe-new.checked').removeClass('hide');
  $(freigabe_checkboxes).children('.freigabe-new.unchecked').addClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// alle für nicht-freigabe markieren (only includes
// filtered_in, i.e. visible people)
window.freigabe_keine = function freigabe_keine() {
  var freigabe_checkboxes = filtered_freigabe_elements();

  $(freigabe_checkboxes).attr('new-freigabe', "false");
  $(freigabe_checkboxes).children('.freigabe-new.neutral').addClass('hide');
  $(freigabe_checkboxes).children('.freigabe-new.checked').addClass('hide');
  $(freigabe_checkboxes).children('.freigabe-new.unchecked').removeClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// toggle through new_freigabe states
window.freigabe_new_markieren = function freigabe_new_markieren(e) {
  var freigabe_element = e.target.closest('li');
  var freigabe_current_element = $(e.target).siblings('i');
  var current_freigabe_status = $(freigabe_element).attr('current-freigabe'); // boolean
  var anstellung_changed = $(freigabe_current_element).hasClass('anstellung-changed-since-freigabe-sv'); // boolean
  var new_freigabe_status = $(freigabe_element).attr('new-freigabe') || "neutral"; // true, false or "neutral"
  var change_to;
  var checkbox;

  if (current_freigabe_status == "true"){
    if (anstellung_changed){
      switch (new_freigabe_status){
      case "neutral":
        change_to = "false";
        checkbox = "unchecked";
        break;
      case "false":
        change_to = "true";
        checkbox = "checked";
        break;
      default:
        change_to = "neutral";
        checkbox = "neutral";
      }
    } else {
      change_to = (new_freigabe_status == "neutral")? "false" : "neutral";
      checkbox = (new_freigabe_status == "neutral")? "unchecked" : "neutral";
    }
  } else {
    change_to = (new_freigabe_status == "neutral")? "true" : "neutral";
    checkbox = (new_freigabe_status == "neutral")? "checked" : "neutral";
  }

  $(freigabe_element).attr('new-freigabe', change_to);
  $(freigabe_element).children('.freigabe-new:not(.' + checkbox + ')').addClass('hide');
  $(freigabe_element).children('.freigabe-new.' + checkbox).removeClass('hide');

  return false; // don't follow html link, i.e. don't reload page
}

// alle freigabe-änderungen verwerfen
window.freigabe_markierung_entfernen = function freigabe_markierung_entfernen() {
  reset_new_member_status_and_checkboxes();
  return false; // don't follow html link, i.e. don't reload page
}

// freigabe-änderungen speichern
window.freigabe_speichern = function freigabe_speichern() {
  var freigabe_elements; // elements holding freigabe info
  var join_ids = []; // array will hold id's where freigabe is to be granted
  var leave_ids = []; // array will hold id's where freigabe is to be withdrawn

  freigabe_elements = filtered_freigabe_elements().filter('[new-freigabe = "true"], [new-freigabe = "false"]');

  var is_current_freigabe;
  var is_new_freigabe;
  var anstellung_changed;

  // add ids to join and leave lists
  $(freigabe_elements).each(function(){
    is_current_freigabe = $(this).attr('current-freigabe');
    is_new_freigabe = $(this).attr('new-freigabe');
    anstellung_changed = $(this).children('.freigabe-current').hasClass('anstellung-changed-since-freigabe-sv');

    if (is_current_freigabe == "true" && is_new_freigabe == "false"){
      leave_ids.push($(this).attr("data-id")) // add id to list
    } else if (is_current_freigabe == "false" && is_new_freigabe == "true") {
      join_ids.push($(this).attr("data-id")) // add id to list
    } else if (is_current_freigabe == "true" && is_new_freigabe == "true" && anstellung_changed){
      join_ids.push($(this).attr("data-id")) // add id to list
    }
  });

  if (join_ids.length > 0 || leave_ids.length > 0){
    // show progress bar if conditions are met. select progress bar on people col
    // only in 2-col-layout, otherwise select single progress bar
    if (join_ids.length > 5 || leave_ids.length > 10){
      var progress_bar = $('.col2-progress');
      if (progress_bar[0]){
        $(progress_bar).removeClass("hide");
      } else {
        $('.progress').removeClass("hide");
      }
    }
    var path = "/people/update_people_freigabe";
    $.ajax({
      type: "GET",
      url: path,
      dataType: "script",
      data: {join_ids: JSON.stringify(join_ids), leave_ids:JSON.stringify(leave_ids)},
      complete: function(xmlHTTP, status){
        $('.progress').addClass("hide");
        if (status =! "success"){
          M.toast({html: "Nicht alle Freigaben konnten geändert werden. " +
            "Bitte versuchen Sie es nochmal, oder kontaktieren Sie " +
            "den Seabird-Kundensupport, info@seabird.ch (Code 806)."});
        }
      }
    });
  } else {
    M.toast({html: 'Es sind keine geänderten Freigaben vorhanden. Markieren Sie ' +
      'gewünschte Änderungen bitte im Feld "Neuer Freigabestatus".'});
  }

  return false; // don't follow html link
}


// if clicked on anything but a freigabe element, or if clicked on freigabe button
// twice, reset all freigabe update elements and exit freigabe edit mode.
// if person_element is passed, will only reset this element.
window.freigabe_reset = function freigabe_reset() {

  var freigabe_elements = $('.freigabe');

  reset_new_member_status_and_checkboxes(freigabe_elements);

  // reinitialize listeners
  $('.freigabe-current').each(function(){
    $(this).off();
    $(this).on('click', function(e){
      e.stopPropagation();
    });
  });

  $('.freigabe-new').each(function(){
    $(this).off();
    $(this).on('click', function(e){
      e.stopPropagation();
      freigabe_new_markieren(e);
    });
  });

  // hide checkboxes
  $(freigabe_elements).addClass('hide');

  // hide button set
  $('.freigabe-button-li').addClass('hide');

  // restore "navbar-items" data attribute
  var elt = $('#slplaner-two-col-navbar-right, #slplaner-one-col-navbar')
    .find('.slplaner-col-navbar').first();
  var navbar_items = elt.attr('data-navbar-items');
  var navbar_items_difference = elt.attr('data-navbar-difference');
  if (navbar_items && navbar_items_difference){
    elt.attr('data-navbar-items', (parseInt(navbar_items) - parseInt(navbar_items_difference)));
    elt.attr('data-navbar-difference', null);
  }

  // restore dropdown classes on navbar elts not involved in matching
  $( '.people-list-add-button-li, ' +
     '.people-filter-button-li, ' +
     '.people-list-freigabe-button-li, .people-list-import-one-person-button-li')
    .removeClass('slplaner-go-to-dropdown-li no-propagation');
  $( '.people-list-import-vj-button-li, .people-list-import-one-person-button-li, ' +
     '.people-filter-button-li')
    .removeClass('hide-in-dropdown');

  // restore match link elements which were hidden during freigabe
  var match_links = $('.match-link-li');
  if (match_links){
    $(match_links).removeClass('hide');
  }

  window.freigabe_edit_on = false;
}

window.call_freigabe_reset = function call_freigabe_reset(e){
  // if edit freigabe is in progress (and thus may need to be reset)
  if (window.freigabe_edit_on) {
    var classes = e.target.classList;
    var reset_flag = true;

    if ( classes.contains("freigabe-button-li") || classes.contains("freigabe-new") ||
         classes.contains("freigabe-current")) {
      reset_flag = false;
    }

    // if not clicked on one of the above elements, call freigabe reset
    if (reset_flag){
      freigabe_reset();
    }
  }
}

// freigabe_elements is optional parameter
window.reset_new_member_status_and_checkboxes = function reset_new_member_status_and_checkboxes(freigabe_elements){
  if (freigabe_elements === undefined) {
    var freigabe_elements = $('.freigabe');
  }
  $(freigabe_elements).attr("new-freigabe", "neutral");
  $(freigabe_elements).children('.freigabe-new.neutral').removeClass('hide');
  $(freigabe_elements).children('.freigabe-new.checked').addClass('hide');
  $(freigabe_elements).children('.freigabe-new.unchecked').addClass('hide');
}

window.filtered_freigabe_elements = function filtered_freigabe_elements(){
  return $('.p-header-li:not(.hide) .freigabe');
}


///////////////////////////////////////////////////////////////////////////////
// änderungen auf person: warnsignal entfernen und notification_status auf
// dismissed setzen
///////////////////////////////////////////////////////////////////////////////

// action is controller action the function was called from
window.dismiss_freigabe_notification = function dismiss_freigabe_notification(li_elt, alert_elt, action){
  var freigabe_id = $(alert_elt).attr('data-freigabe-id');
  var alert_elts;
  var li_elts;

  // in index_anstellungen, there can be more than one notification per freigabe
  if (action == "index_anstellungen"){
    alert_elts = $('.person-alert').filter(function(){
      return $(this).attr('data-freigabe-id') == freigabe_id;
    });
    li_elts = $(alert_elts).closest('li');
  } else {
    alert_elts = alert_elt;
    li_elts = li_elt;
  }

  var new_tooltip;
  if ( ["Änderung: Freigabe", "Änderung: Daten geändert"].includes(li_elt.attr('data-tooltip')) ){
    new_tooltip = "Anstellen";
  } else {
    new_tooltip = "Nicht anstellen";
  }

  if (freigabe_id){
    var path = "/schulverwaltungs/" + freigabe_id + "/dismiss_freigabe_notification";
    $.ajax({
      type: "PATCH",
      url: path,
      dataType: "script",
      data: { freigabe_id: freigabe_id,
              called_from: action,
              authenticity_token: $('[name="csrf-token"]')[0].content
            },
      complete: function(xmlHTTP, status){
        if (status == "success"){
          alert_elts.css('visibility', 'hidden');
          $(li_elts).attr('data-tooltip', new_tooltip);
        } else if (status == "nocontent"){
          // do nothing
        } else {
          M.toast({html: "Die Freigabe-Anzeige konnte nicht entfernt werden. Bitte versuchen Sie es nochmal, " +
          "oder kontaktieren Sie den Seabird-Kundensupport, info@seabird.ch. (Code 71a)"});
        }
      }
    });
  }
  return false; // don't follow html link, i.e. don't reload page
}
